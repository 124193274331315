import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import LoadingIndicator from '../../../components/loading-indicator';
import UserLogo from '../../../components/user-logo';
import { useUser } from '../../../context/user-context';
import { AffiliatesHeadType, AffiliateType, ReferralUsersResponseType, TopAffiliatesType } from '../../../models/api/dashboard-model';
import { formatCurrency, generateShortUserName } from '../../../utils/utils';
import RefferalUsersWidget from './../../../components/wigets/refferal-users-widget';
import AffiliateItem from './components/affiliate-item';
import TopPerformance from './components/top-performance';

type PropsType = {
    refferalData?: ReferralUsersResponseType;
    refferalAmount?: number;
    head?: AffiliatesHeadType;
    affiliates: AffiliateType[];
    parentAffiliates?: AffiliateType[];
    userAffiliates?: AffiliateType[];
    topAffiliates?: TopAffiliatesType[];
    loading: boolean;
    loadAffiliates: (id: number, line: number) => void;
    loadSearchAffiliates?: (id: number, line: number) => void;
};

const AffiliateView: React.FC<PropsType> = ({
    refferalData,
    refferalAmount,
    head,
    affiliates,
    topAffiliates,
    loadAffiliates,
    loadSearchAffiliates,
    parentAffiliates,
    userAffiliates,
    loading,
}) => {
    const { user } = useUser();

    const renderAffilates = (items: AffiliateType[], search = false) => {
        if (items?.length)
            return (
                <Accordion>
                    {items.map((i, index) => (
                        <div className="affiliate-item" key={`event${i.id}`}>
                            <AffiliateItem
                                eventKey={`event${i.id}`}
                                item={i}
                                loadChild={search ? loadSearchAffiliates || loadAffiliates : loadAffiliates}
                            />
                            <Accordion.Collapse eventKey={`event${i.id}`}>{<>{renderAffilates(i.items, search)}</>}</Accordion.Collapse>
                        </div>
                    ))}
                </Accordion>
            );
    };

    return (
        <>
            <div>
                <LoadingIndicator visible={loading} />
                <Row>
                    <Col xs={12} md={12} lg={8} className="affiliate-third-order">
                        {parentAffiliates?.length ? (
                            <Accordion>
                                {parentAffiliates.map((item) => (
                                    <div className="affiliate-item" key={`event${item.id}`}>
                                        <AffiliateItem key={item.id} eventKey={`event${item.id}`} item={item} loadChild={loadAffiliates} />
                                    </div>
                                ))}
                                {/* <div className="affiliate-item">
                                    <Row className="item-info-container-client">
                                        <Col className="user-profile">
                                            <div className="user-img">
                                                <UserLogo name={generateShortUserName(user?.name, user?.surname)} small />
                                            </div>
                                            <div className="user-info">
                                                <div className="primary-text">{user?.username}</div>
                                                <div className="secondary-text">Network</div>
                                            </div>
                                        </Col>
                                        <Col className="affilate-amount">
                                            <div className="primary-text">{formatCurrency(head?.totalBonus)}</div>
                                            <div className="secondary-text">Paid to you</div>
                                        </Col>
                                        <Col className="affilate-amount">
                                            <div className="primary-text">{head?.firstLineCount}</div>
                                            <div className="secondary-text">1 line</div>
                                        </Col>
                                        <Col className="affilate-amount">
                                            <div className="primary-text">{head?.secondLineCount}</div>
                                            <div className="secondary-text">2 line</div>
                                        </Col>
                                    </Row>
                                </div> */}
                                {userAffiliates && renderAffilates(userAffiliates, true)}
                            </Accordion>
                        ) : (
                            <Accordion>
                                <div className="affiliate-item">
                                    <Row className="item-info-container-client">
                                        <Col className="user-profile">
                                            <div className="user-img">
                                                <UserLogo name={generateShortUserName(user?.name, user?.surname)} small />
                                            </div>
                                            <div className="user-info">
                                                <div className="primary-text">{user?.username}</div>
                                                <div className="secondary-text">Network</div>
                                            </div>
                                        </Col>
                                        <Col className="affilate-amount">
                                            <div className="primary-text">{formatCurrency(head?.totalBonus)}</div>
                                            <div className="secondary-text">Paid to you</div>
                                        </Col>
                                        <Col className="affilate-amount">
                                            <div className="primary-text">{head?.firstLineCount}</div>
                                            <div className="secondary-text">1 line</div>
                                        </Col>
                                        <Col className="affilate-amount">
                                            <div className="primary-text">{head?.secondLineCount}</div>
                                            <div className="secondary-text">2 line</div>
                                        </Col>
                                    </Row>
                                </div>
                                {renderAffilates(affiliates)}
                            </Accordion>
                        )}
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        {refferalData && (
                            <RefferalUsersWidget
                                firstLevelCount={refferalData?.firstLevel.length}
                                secondLevelCount={refferalData?.secondLevel.length}
                                refferalDividents={refferalAmount ?? 0}
                                isClient
                            />
                        )}
                        {topAffiliates && topAffiliates.length && <TopPerformance topAffiliates={topAffiliates} />}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AffiliateView;
