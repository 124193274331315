import React, { useState } from 'react';
import ProfileAccountModal from './profile-account-modal';

type PropsType = {
    wallet?: string;
    iban?: string;
    updateData: () => void;
};

const ProfileAccountInfo: React.FC<PropsType> = ({ wallet, iban, updateData }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    return (
        <>
            <div className="profile-account-wrapper">
                <div className="profile-image"></div>
                <div className="profile-info">
                    <div className="primary-text">My Account</div>
                    <div className="secondary-text">Crypto/Fiat</div>
                </div>
                <div className="profile-info">
                    <div className="primary-text">USDT</div>
                    <div className="secondary-text">{wallet ?? '-'}</div>
                </div>
                <div className="profile-info">
                    <div className="primary-text">IBAN</div>
                    <div className="secondary-text">{iban ?? '-'}</div>
                </div>
                <div className="btn-container">
                    <button className="btn-border" onClick={() => setShowModal(true)}>
                        Edit
                    </button>
                </div>
            </div>
            <ProfileAccountModal isOpened={showModal} wallet={wallet} iban={iban} onClose={() => setShowModal(false)} updateData={updateData} />
        </>
    );
};

export default ProfileAccountInfo;
