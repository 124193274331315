import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SeifLogo } from '../../../../assets/images/index';
import LoadingIndicator from './../../../../components/loading-indicator';
import BaseInput from './../../../../components/common/base-input';
import BaseButton from './../../../../components/common/base-button';
import { useFormRules } from './../../../../hooks/use-form-rules';
import { useForm } from 'react-hook-form';
import clientService from './../../../../service/clientService';

type PropsType = {
    wallet?: string;
    iban?: string;
    isOpened: boolean;
    onClose: () => void;
    updateData: () => void;
};

type AccountDataFormType = {
    cryptoWallet: string;
    iban: string;
};

const ProfileAccountModal: React.FC<PropsType> = ({ isOpened, onClose, iban, wallet, updateData }) => {
    const { t } = useTranslation();
    const { requiredTrimed, formRules } = useFormRules();
    const [canReplace, setCanReplace] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        trigger,
        reset,
    } = useForm<AccountDataFormType>();

    const ibanValue: string = (watch && watch(`iban`)) ?? '';

    useEffect(() => {
        reset({ cryptoWallet: wallet, iban: iban });
    }, [iban, wallet, reset]);

    useEffect(() => {
        if (ibanValue) {
            const preview = ibanValue
                .toUpperCase()
                .replace(/\W/gi, '')
                .replace(/(.{4})/g, '$1 ');

            canReplace && setValue && setValue(`iban`, preview);
        }
    }, [ibanValue, setValue, canReplace]);

    const updateAccountData = (data: AccountDataFormType) => {
        setLoading(true);
        const formdata = new FormData();
        formdata.append('cryptoWallet', data.cryptoWallet);
        formdata.append('iban', data.iban);

        clientService
            .updateProfile(formdata)
            .then(() => {
                updateData();
                onClose();
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <LoadingIndicator visible={loading} />
            <Modal show={isOpened} size="sm" onHide={onClose} centered>
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <div className="auth-logo">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <div>
                            <BaseInput
                                {...register('iban', { ...requiredTrimed, ...formRules.iban })}
                                errors={errors}
                                type="text"
                                label={t('withdrawal.iban')}
                                trigger={trigger}
                                onKeyDown={(e) => {
                                    if (e.nativeEvent.key === 'Backspace' || e.nativeEvent.key === 'Delete') {
                                        setCanReplace(false);
                                    } else {
                                        setCanReplace(true);
                                    }
                                }}
                            />
                            <BaseInput
                                {...register('cryptoWallet', { ...requiredTrimed })}
                                errors={errors}
                                type="text"
                                label={t('profile.wallet')}
                                trigger={trigger}
                            />

                            <BaseButton onClick={handleSubmit(updateAccountData)} className="auth-sign-in-btn btn-center mt-4">
                                {t('common.save')}
                            </BaseButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProfileAccountModal;
