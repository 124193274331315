import moment from 'moment';
import React, { useContext } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import UserLogo from '../../../../components/user-logo';
import {
    ClientInvestmentItemType,
    ClientInvestmentResponseType,
    ClientStatisticPortfolioType,
    RegisteredUserType,
    SaleInvestmentItemType,
    SaleInvestmentResponseType,
} from '../../../../models/api/dashboard-model';
import { formatCurrency, generateShortUserName } from '../../../../utils/utils';
import { useTranslation } from 'react-i18next';
import Paginator from '../../../../components/paginator';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';

type PropsType = {
    eventKey: string;
    item: RegisteredUserType;
    pageIndex: number;
    pagination?: ClientInvestmentResponseType;
    loadInvestments: (id: number, page: number) => void;
    portfolios: ClientStatisticPortfolioType[];
    investments: ClientInvestmentItemType[];
    loadPortfolios: (id: number) => void;
};

const RegisteredUserItem: React.FC<PropsType> = ({
    eventKey,
    item,
    investments,
    pageIndex,
    pagination,
    portfolios,
    loadInvestments,
    loadPortfolios,
}) => {
    const { t } = useTranslation();
    return (
        <div className="affiliate-item">
            <div className="item-info-container">
                <div className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.name, item.surname)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{`${item.name} ${item.surname}`}</div>
                        <div className="secondary-text">{item.parentName}</div>
                    </div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.email}</div>
                    <div className="secondary-text">{`Registered - ${moment(item.createdAt).format('DD.MM.yyyy')}`}</div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.phone}</div>
                    <div className="secondary-text">{`${t('dashboard.total')} - ${formatCurrency(item.totalInvested)}`}</div>
                </div>
                {/* <div></div> */}
                <div className="affilate-action">
                    <ContextAwareToggle eventKey={eventKey} callback={() => loadInvestments(item.id, 0)} />
                </div>
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <Accordion defaultActiveKey="0">
                    <div className="item-portfolio-container">
                        <div className="item-info-container p-0">
                            <div className="affilate-amount">
                                <div className="secondary-text">{t('invest.invest')}</div>
                            </div>
                            <div className="affilate-amount"></div>
                        </div>
                        {investments.map((item, index) => (
                            <>
                                <div className="item-info-container" key={index}>
                                    <div className="affilate-amount">
                                        <div className="primary-text">{formatCurrency(item.amount)}</div>
                                        <div className="secondary-text">&nbsp;</div>
                                    </div>
                                    <div className="affilate-amount">
                                        <div className="primary-text">{item.type ? t(`enums.investType.${item.type}`) : ''}</div>
                                        <div className="secondary-text">{moment(item.createdAt).format('DD.MM.YYYY')}</div>
                                    </div>
                                    <div className="affilate-amount">
                                        <div className="secondary-text align-items-center">
                                            <ContextArrowAwareToggle eventKey={`port${item.id}`} callback={() => loadPortfolios(item.id)} />
                                        </div>
                                    </div>
                                </div>
                                <Accordion.Collapse eventKey={`port${item.id}`}>
                                    <>
                                        {portfolios.map((port, i) => (
                                            <div className="item-info-container p-0" key={i}>
                                                <div className="affilate-amount"></div>
                                                <div className="affilate-amount">
                                                    <div className="secondary-text">{port.position.type}</div>
                                                </div>
                                                <div className="affilate-amount">
                                                    <div className="secondary-text">{formatCurrency(port.initialAmount)}</div>
                                                </div>
                                            </div>
                                        ))}

                                        {portfolios.length === 0 && (
                                            <div className="portfolio-empty">
                                                <span>{t('affilate.empty-list')}</span>
                                            </div>
                                        )}
                                    </>
                                </Accordion.Collapse>
                            </>
                        ))}
                        <div className="mt-2">
                            <Paginator
                                pageIndex={pageIndex}
                                dataLength={pagination?.totalElements}
                                onPageChanged={(newPage: number) => {
                                    loadInvestments(item.id, newPage);
                                }}
                                pageSize={10}
                            />
                        </div>

                        {investments.length === 0 && (
                            <div className="portfolio-empty">
                                <span>{t('affilate.empty-list')}</span>
                            </div>
                        )}
                    </div>
                </Accordion>
            </Accordion.Collapse>
        </div>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | string;
};

function ContextAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" onClick={decoratedOnClick}>
            {isCurrentEventKey ? 'Hide' : 'Show'}
        </button>
    );
}

function ContextArrowAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div role="button" className="p-2" onClick={decoratedOnClick}>
            {isCurrentEventKey ? <ChevronUp /> : <ChevronDown />}
        </div>
    );
}

export default RegisteredUserItem;
