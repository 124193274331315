import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router';
import usePrepareLink from '../../../hooks/use-prepare-link';
import { useUrlQuery } from '../../../hooks/use-url-query';
import { CountryType, RegistrationDataType, RegistrationErrorType } from '../../../models/api/auth-models';
import { RouteModalPropsType } from '../../../models/models';
import { AuthRoutes } from '../../../routes/routes';
import authService from './../../../service/authService';
import RegistrationView from './registration-view';
import { useTranslation } from 'react-i18next';
import clientService from '../../../service/clientService';

const Registration: React.FC<RouteModalPropsType> = ({ isOpened, onClose }) => {
    const history = useHistory();

    const urlQuery = useUrlQuery();
    const token = urlQuery.get('token');
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [countries, setCountries] = useState<CountryType[]>([]);
    const [code, setCode] = useState<string>('+421');

    const employeMatch = useRouteMatch({ path: AuthRoutes.REGISTRATION_EMPLOYEE });
    const clientMatch = useRouteMatch({ path: AuthRoutes.REGISTRATION_CLIENT });

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setError,
        trigger,
        setValue,
        getValues,
        control,
    } = useForm<RegistrationDataType>();

    const registerMessage = usePrepareLink({
        to: AuthRoutes.PROCESS_SUCCESS,
        isRelativePath: true,
    });

    useEffect(() => {
        clientService.getAllCountries().then((res) => {
            setCountries(res.data.countries);
            setValue('countryCode', 'SK');
        });
    }, [setValue]);

    const handleRegistration = async (data: RegistrationDataType) => {
        const trimedData: RegistrationDataType = {
            ...data,
            username: data.username.trim(),
            name: data.name.trim(),
            surname: data.surname.trim(),
            phone: `${code}${data.phone}`,
        };
        setLoading(true);
        if (token) {
            if (employeMatch) return authService.registerEmployee(trimedData, token).then(onSuccess, onError);
            if (clientMatch) return authService.registerClient(trimedData, token).then(onSuccess, onError);
            else return authService.registerWithReferal(trimedData, token).then(onSuccess, onError);
        } else {
            return authService.register(trimedData).then(onSuccess, onError);
        }
        function onSuccess() {
            setLoading(false);
            history.replace({
                ...registerMessage,
                state: {
                    message: t('auth.registerMessage', { email: data.email }),
                },
            });
        }

        function onError(error: AxiosError<RegistrationErrorType>) {
            setLoading(false);
            error.response?.data?.subErrors?.forEach((error) => {
                setError(error.field as keyof RegistrationDataType, {
                    type: 'manual',
                    message: error.message,
                });
            });
        }
    };

    return (
        <>
            <RegistrationView
                countries={countries}
                handleRegistration={handleSubmit(handleRegistration)}
                register={register}
                errors={errors}
                watch={watch}
                trigger={trigger}
                loading={loading}
                code={code}
                handleCode={setCode}
                isOpened={isOpened}
                control={control}
                setValue={setValue}
                getValues={getValues}
                handleClose={onClose}
            />
        </>
    );
};

export default Registration;
