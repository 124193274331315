import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { useUser } from '../../../context/user-context';
import { LoginDataType, LoginResponseType } from '../../../models/api/auth-models';
import clientService from '../../../service/clientService';
import { useLocalStorage } from './../../../hooks/use-local-storage';
import authService from './../../../service/authService';
import LoginView from './login-view';
import { useNotification } from '../../../context/notification-context';
import { RolesEnum } from '../../../models/enums';
import ForgotPassword from '../forgot-password/forgot-password'; // Import the ForgotPassword component
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import Routes from '../../../routes/routes';

const Login: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const history = useHistory();

    const { loadCount, loadAlerts, loadWithdrawalRequests } = useNotification();
    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm<LoginDataType>();
    const { setUser } = useUser();
    const { addToast } = useToasts();
    const { setValue: setToken, clearStore } = useLocalStorage<string>('TOKEN', '');
    const [loading, setLoading] = useState<boolean>(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const handleLogin = async (data: LoginDataType) => {
        setLoading(true);
        authService.login(data).then(onSuccess, onError);

        function onSuccess(response: AxiosResponse<LoginResponseType>) {
            setToken(response.data.jwt);
            clientService.getUser().then(
                (response) => {
                    setUser(response.data.user);
                    if (!!response.data.user?.roles?.includes(RolesEnum.ADMIN)) {
                        loadWithdrawalRequests();
                    }
                    if (!!response.data.user?.roles?.includes(RolesEnum.USER)) {
                        loadCount();
                        loadAlerts();
                    }
                },
                () => {
                    clearStore();
                },
            );
        }

        function onError(error: AxiosError) {
            setLoading(false);
            addToast(error?.response?.data.message, { appearance: 'error' });
        }
    };
    const handleModalClose = (root?: boolean) => {
        setShowForgotPassword(false);

        if (root) return history.replace(Routes.ROOT);

        return history.goBack();
    };
    const handleForgotPasswordClick = (event: React.MouseEvent) => {
        event.preventDefault(); // Prevent default navigation
        setShowForgotPassword(true); // Show the modal
    };

    return (
        <>
            <LoginView handleLogin={handleSubmit(handleLogin)} register={register} errors={errors} trigger={trigger} loading={loading} />
        </>
    );
};

export default Login;
