//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import BaseInput from '../../../../components/common/base-input';
import { useFormRules } from '../../../../hooks/use-form-rules';
import clientService from '../../../../service/clientService';
import { formatCurrency } from '../../../../utils/utils';
import LoadingIndicator from '../../../../components/loading-indicator';
import { Row, Col, Container, Dropdown, Button, Modal } from 'react-bootstrap';

type PropsType = {
    handleReinvest: () => void;
};

type FormDataType = {
    amount: number;
};

const ReinvestReferral: React.FC<PropsType> = ({ handleReinvest }) => {
    const [bonusAmount, setBonusAmount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        trigger,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormDataType>();
    const { formRules } = useFormRules();

    const { addToast } = useToasts();
    const { t } = useTranslation();

    const getAmount = () => {
        clientService.getAvailableProfits().then((res) => {
            setBonusAmount(res.data.bonusAmount);
        });
    };

    useEffect(() => {
        getAmount();
    }, []);

    const reinvest = (data: FormDataType) => {
        setLoading(true);
        clientService
            .reinvestProfits(data.amount)
            .then(() => {
                addToast(t('toast.reinvestSuccess'));
                getAmount();
                setValue('amount', '');
            })
            .finally(() => {
                setLoading(false);
                handleReinvest();
            });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            {/* <LoadingIndicator visible={loading} />
            <div className="reinvest-container p-4">
                <div className="reinvest-balance-text">
                    {t('withdrawal.totalProfit')} : {formatCurrency(amount)}
                </div>
                <div className="d-flex justify-content-between">
                    <div className="reinvest-input">
                        <BaseInput
                            {...register('amount', { ...formRules.number, ...formRules.min(10), ...formRules.max(amount) })}
                            label={t('invest.reinvestQuestion')}
                            trigger={trigger}
                            errors={errors}
                        />
                    </div>
                    <div className="d-flex align-items-end justify-content-end">
                        <button className="base-btn" onClick={handleSubmit(reinvest)}>
                            Reinvest
                        </button>
                    </div>
                </div>
            </div> */}

            <div className="bonus-actual-profits mt-3">
                <Row className="bonus-wrapp-container">
                    <Col>
                        <Row>
                            <Col>
                                <span className="bonus-profits-title">{t('invest.bonusProfits')} / </span>
                                <span className="bonus-actual-title">{t('invest.actualReferralProfit')}</span>
                            </Col>
                        </Row>
                        <Row>
                            <span className="bonus-profit-amount">{formatCurrency(bonusAmount)}</span>
                        </Row>
                    </Col>
                    <Col className="bonus-actual-desc">
                        <p>{t('invest.reinvestDescription')}</p>
                    </Col>
                    <Col className="reinvest-modal-button">
                        <Button className="btn sign-in-btn" onClick={handleShow} disabled={bonusAmount < 10}>
                            {t('invest.reinvestButton')}
                        </Button>

                        <Modal show={show} onHide={handleClose} centered className="reinvest-modal-dialog">
                            <Modal.Header closeButton className="border-0">
                                {/* <Modal.Title>Modal heading</Modal.Title> */}
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <span className="actual-reinvest-balance">My balance for reinvest:</span>
                                        <span className="bonus-reinvest-amount">{formatCurrency(bonusAmount)}</span>
                                    </Col>
                                    <Col md="auto">
                                        <span className="reinvest-input-title">How much do you want to reinvest?</span>
                                        <BaseInput
                                            {...register('amount', { ...formRules.number, ...formRules.min(10), ...formRules.max(bonusAmount) })}
                                            label={t('invest.reinvestTitle')}
                                            trigger={trigger}
                                            errors={errors}
                                            className="umud pr-2"
                                        />
                                    </Col>
                                    <Col className="reinvest-modal-button">
                                        <button className="sign-in-btn" onClick={handleSubmit(reinvest)}>
                                            Reinvest
                                        </button>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className="border-0">
                                {/* <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleClose}>
                                    Save Changes
                                </Button> */}
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </div>
            {/* <div className="invest-referral-list">
                {referrals.map((item) => (
                    <div key={item.id} className="referral-item affiliate-item">
                        <div>
                            <Controller
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <div className="custom-control custom-checkbox">
                                        <input {...field} type="checkbox" className="custom-control-input" />
                                        <label className="custom-control-label">{item.from}</label>
                                    </div>
                                )}
                                control={control}
                                name={`${item.id}`}
                            />
                        </div>
                        <div>{moment(item.date).format('DD.MM.YYYY')}</div>
                        <div>{formatCurrency(item.amount)}</div>
                    </div>
                ))}
            </div> */}
        </div>
    );
};

export default ReinvestReferral;
