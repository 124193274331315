import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WithdrawalRequestType } from '../../../../models/api/dashboard-model';
import { formatCurrency } from '../../../../utils/utils';

type PropsType = {
    data?: WithdrawalRequestType[];
    title: string;
};

const WithdrawHistory: React.FC<PropsType> = ({ data, title }) => {
    return (
        <div className="history">
            <div className="history-title">{title}</div>
            {data ? (
                data.map((item, index) => (
                    <>
                        <div className="hsitory-item" key={index}>
                            <div className="history-item-left">{formatCurrency(item.amount)}</div>
                            <div className="history-item-right">{moment(item.createdAt).format('DD-MM-yyyy')}</div>
                        </div>
                    </>
                ))
            ) : (
                <>
                    <div className="history-empty">
                        <div className="divider"></div>
                        <div className="divider"></div>
                        <div className="divider"></div>
                    </div>
                </>
            )}
        </div>
    );
};

export default WithdrawHistory;
