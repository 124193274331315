//@ts-nocheck
import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import { useUser } from '../../context/user-context';
import { useLocalStorage } from '../../hooks/use-local-storage';
import usePrepareLink from '../../hooks/use-prepare-link';
import Routes, { AuthRoutes } from '../../routes/routes';
import NotFound404 from './../../components/NotFond404';
import { TOKEN } from './../../models/const';
import LandingRouteList from './../../routes/landing-route-list';

import LandingHeader from './components/landing-header';
import SectionHome from './sections/section-home';
import Login from '../auth/login/login'; // Import the Login component

const LandingPage: React.FC = () => {
    const history = useHistory();
    const { value: token } = useLocalStorage<string>(TOKEN, '');
    const { user } = useUser();
    const homeRef = useRef<null | HTMLElement>(null);

    const handleModalClose = (root?: boolean) => {
        if (root) return history.replace(Routes.ROOT);

        return history.goBack();
    };

    const handleScroll = (to: string) => {
        switch (to) {
            case 'home': {
                return homeRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }
            default:
                return;
        }
    };

    const handleLogin = async (data: LoginDataType) => {
        setLoading(true);
        authService.login(data).then(onSucces, onError);

        function onSucces(response: AxiosResponse<LoginResponseType>) {
            setToken(response.data.jwt);
            clientService.getUser().then(
                (response) => {
                    setUser(response.data.user);
                    if (!!response.data.user?.roles?.includes(RolesEnum.ADMIN)) {
                        loadWithdrawalRequests();
                    }
                    if (!!response.data.user?.roles?.includes(RolesEnum.USER)) {
                        loadCount();
                        loadAlerts();
                    }
                },
                () => {
                    clearStore();
                },
            );
        }

        function onError(error: AxiosError) {
            setLoading(false);
            addToast(error?.response?.data.message, { appearance: 'error' });
        }
    };

    return (
        <>
            <div className="landing-container home-bg">
                <LandingHeader onLoginClick={handleLogin} handleLinkClick={handleScroll} />
                <div className="box-shadow-effect"></div>
            </div>
            {!user && <LandingRouteList onClose={handleModalClose} />}
            {/* Render the login form directly on the landing page */}
            <div className="login-form-container">
                <Login /> {/* Always open the login form */}
            </div>
            {/* Render the language dropdown outside the modal */}
            <div className="language-dropdown-container">
                <SectionHome />
            </div>
            <Route path={'*'} component={NotFound404} />
        </>
    );
};
export default LandingPage;
