import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Routes, { ALL_ROUTES } from '../routes/routes';
import BaseButton from './common/base-button';
import { useTranslation } from 'react-i18next';

const NotFound404: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const [showPage, setShowPage] = useState<boolean>(false);

    useEffect(() => {
        const exist = ALL_ROUTES.find((item) => history.location.pathname.includes(item));
        if (exist || history.location.pathname == '/') {
            setShowPage(false);
        } else {
            setShowPage(true);
        }
    }, [history.location.pathname]);

    const redirectToHome = () => {
        history.replace(Routes.ROOT, '');
    };

    return showPage ? (
        <div className="not-found-container">
            <BaseButton className="log-in-btn not-found-btn" onClick={redirectToHome}>
                {t('navlinks.home')}
            </BaseButton>
        </div>
    ) : null;
};

export default NotFound404;
