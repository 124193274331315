import React from 'react';

type PropsType = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    className?: string;
};

const BaseButton: React.FC<PropsType> = ({ className, children, ...rest }) => (
    <button className={className ?? 'base-btn'} {...rest}>
        {children}
    </button>
);
export default BaseButton;
