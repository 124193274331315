import { ErrorMessage } from '@hookform/error-message';
import React, { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type CheckboxProps = {
    name: string;
    label: ReactNode;
    id: string;
    checked?: boolean;
    errors?: { [key: string]: any };
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
    className?: string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    autoFocus?: boolean;
};

const BaseCheckbox = forwardRef(
    ({ name, label, id, errors, checked, onChange, className, disabled, autoFocus, onClick }: CheckboxProps, ref: any) => {
        if (ref && !!checked) {
            throw new Error("This is not supported by Internet Explorer 11. Don't use Checkbox with 'checked' when 'ref' is provided!!!");
        }

        const [t] = useTranslation();
        return (
            <div className={`custom-control custom-checkbox ${className ?? 'mb-3'}`}>
                <input
                    onChange={onChange}
                    ref={ref}
                    checked={checked}
                    name={name}
                    type="checkbox"
                    className="custom-control-input"
                    id={id}
                    disabled={disabled}
                    onClick={onClick}
                />
                <label
                    className={classNames('custom-control-label', {
                        'label-error': errors?.[name],
                    })}
                    htmlFor={id}
                >
                    {label}
                </label>
            </div>
        );
    },
);

export default BaseCheckbox;
