import React, { useEffect, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../../components/loading-indicator';
import Paginator from '../../../components/paginator';
import { useDebounce } from '../../../hooks/use-debounce';
import {
    ClientInvestmentItemType,
    ClientStatisticItemType,
    ClientStatisticPortfolioType,
    ClientTotalResponseType,
    PagebleType,
} from '../../../models/api/dashboard-model';
import adminService from '../../../service/adminService';
import BonusWidget, { BonusWidgetEnum } from './components/bonus-widget';
import ClientItem from './components/client-item';
import ReferralWidget from './components/referral-widget';
import PeopleWidget from './components/people-widget';

const ClientStatistic: React.FC = () => {
    const { t } = useTranslation();

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [parent, setParent] = useState<string>('');
    const [clients, setClients] = useState<ClientStatisticItemType[]>([]);
    const [pagination, setPagination] = useState<PagebleType<ClientStatisticItemType>>();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [portfolios, setPortfolios] = useState<ClientStatisticPortfolioType[]>([]);
    const [investments, setInvestments] = useState<ClientInvestmentItemType[]>([]);
    const [total, setTotal] = useState<ClientTotalResponseType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [countryCode, setCountryCode] = useState<string>('SK');
    const [widgetYear, setWidgetYear] = useState<number>(2021);

    const debounceNameSearch = useDebounce<string>(name.trim(), 600);
    const debounceUsernameSearch = useDebounce<string>(username.trim(), 600);
    const debounceEmailSearch = useDebounce<string>(email.trim(), 600);
    const debounceParentSearch = useDebounce<string>(parent.trim(), 600);

    useEffect(() => {
        adminService.getClientTotal(countryCode, widgetYear).then((res) => {
            setTotal(res.data);
        });
    }, [countryCode, widgetYear]);

    const loadStatistics = (searchName: string, searchEmail: string, searchParent: string, username: string, page: number) => {
        setLoading(true);
        adminService
            .getClientStatistics(searchName, searchEmail, searchParent, username, { page })
            .then((res) => {
                setClients(res.data.content);
                setPagination(res.data);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadStatistics(debounceNameSearch, debounceEmailSearch, debounceParentSearch, debounceUsernameSearch, pageIndex);
    }, [pageIndex, debounceNameSearch, debounceEmailSearch, debounceParentSearch, debounceUsernameSearch]);

    const loadPortfolios = (id: number) => {
        setLoading(true);
        adminService
            .getClientPortfolios(id)
            .then((res) => setPortfolios(res.data))
            .finally(() => {
                setLoading(false);
            });
    };

    const loadInvestments = (userId: number) => {
        setLoading(true);
        adminService
            .getClientInvestments(userId, { size: 10 })
            .then((res) => setInvestments(res.data.content))
            .finally(() => {
                setLoading(false);
            });
    };

    const updateColor = (color: string, id: number) => {
        adminService.updateColor(color, id).then(() => {
            loadStatistics(debounceNameSearch, debounceEmailSearch, debounceParentSearch, debounceUsernameSearch, pageIndex);
        });
    };

    return (
        <div className="statistic-container">
            <div className="search-wrapper">
                <div className="form-floating">
                    <input
                        type="text"
                        id=""
                        placeholder=" "
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                    <label htmlFor="nameSearch" className="form-label mb-2">
                        {t('common.nameSearch')}
                    </label>
                </div>
                <div className="form-floating">
                    <input
                        type="text"
                        id=""
                        placeholder=" "
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                    <label htmlFor="nameSearch" className="form-label mb-2">
                        Email Search
                    </label>
                </div>
                <div className="form-floating">
                    <input
                        type="text"
                        id=""
                        placeholder=" "
                        value={parent}
                        onChange={(e) => setParent(e.currentTarget.value)}
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                    <label htmlFor="nameSearch" className="form-label mb-2">
                        Parent Name Search
                    </label>
                </div>
                <div className="form-floating">
                    <input
                        type="text"
                        id=""
                        placeholder=" "
                        value={username}
                        onChange={(e) => setUsername(e.currentTarget.value)}
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                    <label htmlFor="nameSearch" className="form-label mb-2">
                        Userame Search
                    </label>
                </div>
            </div>
            <div className="grid-container">
                <div className="statistic-list">
                    <div className="client-list-header">
                        <div className="text-left">Name / Username / Refferals</div>
                        <div>Total invest / Last invest</div>
                        <div>Deposit / Date</div>
                        <div>Real / Agio</div>
                    </div>
                    <Accordion defaultActiveKey="0">
                        {clients.map((i, index) => (
                            <ClientItem
                                key={index}
                                eventKey={`key${i.id}`}
                                item={i}
                                portfolios={portfolios}
                                investments={investments}
                                loadPortfolios={loadPortfolios}
                                loadInvestments={loadInvestments}
                                updateColor={updateColor}
                            />
                        ))}
                        {clients.length === 0 && (
                            <div className="portfolio-empty">
                                <span>{t('affilate.empty-list')}</span>
                            </div>
                        )}
                    </Accordion>
                    <LoadingIndicator visible={loading} />
                    <Paginator
                        pageIndex={pageIndex}
                        dataLength={pagination?.totalElements}
                        onPageChanged={(newPage: number) => {
                            setPageIndex(newPage);
                        }}
                        pageSize={20}
                    />
                </div>
                <div className="statistic-widgets">
                    <ReferralWidget total={total?.totalInvested} agio={total?.totalAgio} />
                    <BonusWidget data={total?.totalInvestedByMonth} type={BonusWidgetEnum.INVEST} />
                    <BonusWidget data={total?.totalPayoutsByMonth} type={BonusWidgetEnum.PAYOUT} />
                    <PeopleWidget
                        totalRegisteredByMonth={total?.totalRegisteredByMonth}
                        code={countryCode}
                        handleCode={setCountryCode}
                        year={widgetYear}
                        handleYear={setWidgetYear}
                    />
                </div>
            </div>
        </div>
    );
};

export default ClientStatistic;
