import React from 'react';
import { BaseFormType } from '../../../models/models';
import BaseInput from '../../../components/common/base-input';
import { useFormRules } from '../../../hooks/use-form-rules';
import { ForgotPasswordFormType } from './forgot-password';
import LoadingIndicator from '../../../components/loading-indicator';
import { SeifLogo } from '../../../assets/images';
import BaseButton from '../../../components/common/base-button';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type PropsType = BaseFormType<ForgotPasswordFormType> & {
    handleForgotPassword: () => void;
    loading: boolean;
    handleClose: () => void;
    isOpened: boolean;
};

const ForgotPasswordView: React.FC<PropsType> = ({ handleForgotPassword, register, errors, trigger, loading, handleClose, isOpened }) => {
    const { formRules } = useFormRules();
    const { t } = useTranslation();

    return (
        <>
            <LoadingIndicator visible={loading} />
            <Modal show={isOpened} onHide={handleClose} centered backdrop="static">
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <div className="auth-logo mb-4">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <form onSubmit={handleForgotPassword}>
                            <BaseInput
                                {...register('email', { ...formRules.email })}
                                errors={errors}
                                type="text"
                                className=""
                                label={t('auth.email')}
                                trigger={trigger}
                            />

                            <BaseButton type="submit" className="auth-sign-in-btn btn-center mt-4">
                                {t('auth.send')}
                            </BaseButton>
                            <BaseButton className="auth-sign-in-btn btn-center mt-4" onClick={handleClose}>
                                {t('auth.close')}
                            </BaseButton>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ForgotPasswordView;
