import Button from '@restart/ui/esm/Button';
import React, { useEffect, useRef, useState } from 'react';
import { Card, CardGroup, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { BankBuilding, BankCard, BtcEthCoin, InfoIcon, PerformanceIcon, PerformancePortfolioMobileIcon } from '../../../assets/images';
import { InvestmentHistory, InvestmentResponseType } from '../../../models/api/dashboard-model';
import { ClientDashboardRoutes } from '../../../routes/routes';
import { formatCurrency } from '../../../utils/utils';
import clientService from './../../../service/clientService';
import CheckoutButton from './components/checkout-button';
import InvestAnnualStatistic from './components/invest-annual-statistics';
import InvestHistory from './components/invest-history';
import ReinvestReferral from './components/reinvest-referral';
const priceList = [100, 300, 500, 1000, 2000, 5000, 10000];

const Invest: React.FC = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [amount, setAmount] = useState<number>(0);
    const [cryptoAmount, setCryptoAmount] = useState<number>(0);
    const [investmentInfo, setInvestmentInfo] = useState<InvestmentResponseType>();
    const [investmentHistory, setInvestmentHistory] = useState<InvestmentHistory[]>([]);

    const getInvestment = () => {
        clientService.getInvestment().then((res) => {
            setInvestmentInfo(res.data);
        });
    };

    const getInvestmentHistory = () => {
        clientService.getInvestmentHistory().then((res) => {
            setInvestmentHistory(res.data.history);
        });
    };

    useEffect(() => {
        getInvestment();
        getInvestmentHistory();
    }, []);

    const updateData = () => {
        getInvestment();
        getInvestmentHistory();
    };

    const cryptoInvest = () => {
        clientService
            .buyWithCrypto({ amount: priceList[cryptoAmount], redirectUrl: `${process.env.REACT_APP_API_URL}${ClientDashboardRoutes.INVEST}` })
            .then(({ data }) => {
                window.location.href = data.data.hosted_url;
            });
    };

    const investDisclaimer = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('invest.investDisclaimer')}
        </Tooltip>
    );

    return (
        <div className="invest-page-container">
            <div className="invest-grid-container">
                <div className="invest-widget">
                    <Row>
                        <CardGroup className="align-items-center">
                            <Card className="d-block justify-content-center border-0">
                                {/* <span className="invest-label">{t('invest.invest')}</span> */}
                                <div className="d-flex justify-content-center">
                                    <Card.Img className="invest-card-image" variant="top" src={BankCard} />
                                </div>
                                <Card.Body className="d-grid align-items-center">
                                    <Card.Title className="text-center">
                                        <span className="invest-label">{priceList[amount]}€</span>
                                    </Card.Title>
                                    <Card.Text className="d-flex justify-content-center">
                                        <div className="invest-range-input">
                                            <input
                                                type="range"
                                                min="0"
                                                max="5"
                                                step={1}
                                                value={amount}
                                                onChange={(e) => setAmount(+e.target.value)}
                                            />
                                            <div className="d-flex justify-content-between text-muted">
                                                <span>100€</span>
                                                <span>5000€</span>
                                            </div>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="border-0">
                                    <div className="text-center">
                                        <CheckoutButton amount={priceList[amount]} update={updateData}></CheckoutButton>
                                    </div>
                                </Card.Footer>
                            </Card>
                            <Card className="d-block justify-content-center border-0">
                                <div className="text-center">
                                    <Card.Img className="invest-card-image" variant="top" src={BtcEthCoin} />
                                </div>
                                <Card.Body className="crypto-card-body align-items-center">
                                    <Card.Title>
                                        <div className="text-center">
                                            <span className="invest-label">{t('invest.paymentCryptoDetails')}</span>{' '}
                                            <span className="invest-label">{priceList[cryptoAmount]}€</span>
                                        </div>
                                    </Card.Title>
                                    <Card.Text className="d-flex justify-content-center">
                                        <div className="invest-range-input">
                                            <input
                                                type="range"
                                                min="0"
                                                max="5"
                                                step={1}
                                                value={cryptoAmount}
                                                onChange={(e) => setCryptoAmount(+e.target.value)}
                                            />
                                            <div className="d-flex justify-content-between text-white">
                                                <span>100€</span>
                                                <span>5000€</span>
                                            </div>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="justify-content-center d-flex align-items-center border-0">
                                    <div className="text-center">
                                        <Button className="btn sign-in-btn" onClick={cryptoInvest}>
                                            {t('navlinks.invest')}
                                        </Button>
                                    </div>
                                </Card.Footer>
                            </Card>
                            <Card className="d-grid justify-content-center border-0">
                                <Card.Img className="invest-card-image " variant="top" src={BankBuilding} />
                                <Card.Body className="d-grid align-items-center">
                                    <Card.Title className="d-flex justify-content-center">
                                        <span className="invest-label">{t('invest.paymentDetails')}</span>
                                    </Card.Title>
                                    <Card.Text className="bank-detail-color">
                                        {t('invest.paymentBeneficiary')}
                                        <br />
                                        <span className="text-white">
                                            Beneficiary: S.E.I.F. LTD <br />
                                            IBAN: GB15 REVO 0099 6981 7732 15 <br />
                                            BIC: REVOGB21 <br />
                                            <span className="bank-detail-color p-0">{t('invest.paymentReference')}</span> <br />
                                            {t('invest.paymentUsername')} <br />
                                            {t('invest.paymentEmail')} <br />
                                        </span>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="border-0">
                                    <div className="text-center">
                                        <a href="/docs/bank-details.pdf" className="base-btn" target="_blank">
                                            {t('invest.individualInvest')}
                                        </a>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </CardGroup>
                        {/* <Col lg={10}>
                            <div className="invest-range-input">
                                <Dropdown
                                    className="mobile-invest-section pb-3 search-typehead"
                                    onSelect={(eventKey: any, event: any) => {
                                        setAmount(eventKey as number);
                                    }}
                                >
                                    <Dropdown.Toggle className="mobile-invest-btn">
                                        {priceList[amount]}
                                        <span className="px-1">€</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="mobile-invest-dropdown">
                                        {priceList.map((item, index) => (
                                            <Dropdown.Item eventKey={index} key={index}>
                                                {item}
                                                <span className="px-1">€</span>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="web-invest-section">
                                    <input
                                        type="range"
                                        value={amount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setAmount(+e.target.value);
                                        }}
                                        className="form-range"
                                        min="0"
                                        max="6"
                                        step="1"
                                    />
                                    <div className="amount-grid-container">
                                        {priceList.map((item) => (
                                            <div key={item} className="amount-item">
                                                {formatCurrency(item)}
                                            </div>
                                        ))}
                                    </div>
                                    <Row>
                                        <img src={BankCard} alt="" />
                                        <span className="invest-amount">500€</span>
                                        <input type="range" min="1" max="100" value="50" />
                                        <button className="btn sign-in-btn">Invest</button>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} className="d-flex">
                            <div className="invest-btn-wrapper">
                                <CheckoutButton amount={priceList[amount]} update={getInvestment} />
                                <a href="/docs/bank-details.pdf" className="base-btn" target="_blank">
                                    {t('invest.individualInvest')}
                                </a>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </div>
            <div>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={investDisclaimer}>
                    <Button className="bg-transparent border-0">
                        <img src={InfoIcon} className="info-icon-size" alt="" />
                    </Button>
                </OverlayTrigger>
            </div>
            <div className="deposit-widget">
                <Row>
                    <Col xs={12} lg={4}>
                        <div className="primary-text">{t('invest.totalDeposit')}</div>
                        <div className="secondary-text">{formatCurrency(investmentInfo?.allInvested)}</div>
                    </Col>
                    <Col xs={12} lg={4} className="last-deposit">
                        <div className="primary-text">{t('invest.lastDeposit')}</div>
                        <div className="secondary-text">{formatCurrency(investmentInfo?.lastInvested)}</div>
                    </Col>
                    <Col xs={12} lg={4}>
                        <div className="primary-text">{t('invest.info')}</div>
                        <div className="info-text">{t('invest.infoText')}</div>
                    </Col>
                </Row>
            </div>

            <Row className="invest-widgets-grid-container d-flex">
                {/* <InvestChart /> */}
                <Col xs={12} lg="9">
                    <div className="invest-chart-container">
                        {/* {t('invest.portfolioPerformanceChart')} <br />
                        {t('invest.comingSoon')} */}
                        <img src={PerformanceIcon} alt="" className="portfolio-performance-image" />
                        <img src={PerformancePortfolioMobileIcon} alt="" className="portfolio-performance-image-mobile" />
                    </div>
                </Col>
                <Col xs={12} lg="3">
                    {investmentInfo?.annualInvestment && <InvestAnnualStatistic annual={investmentInfo.annualInvestment} />}
                    <InvestHistory investmentHistory={investmentHistory} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ReinvestReferral handleReinvest={updateData} />
                </Col>
            </Row>
        </div>
    );
};

export default Invest;
