import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeStatisticTotalItem } from '../../../models/api/dashboard-model';
import { formatCurrency } from '../../../utils/utils';

type PropsType = {
    data: EmployeeStatisticTotalItem[];
};

const EmployeeWidget: React.FC<PropsType> = ({ data }) => {
    const { t } = useTranslation();

    const currentMonth = useMemo(() => {
        return data.find((item) => item.monthNumber === new Date().getMonth() + 1);
    }, [data]);
    return (
        <div className="profit-container">
            <div className="profit-month">{moment(currentMonth?.monthNumber, 'M').format('MMMM')}</div>
            <div className="profit-amount">{formatCurrency(currentMonth?.invested)}</div>
            <div className="profit-text">{`Total Withdrawal: ${formatCurrency(currentMonth?.withdrawn)}`}</div>

            {data
                .filter((item) => item.monthNumber !== currentMonth?.monthNumber)
                // .reverse()
                .map((item) => (
                    <div className="profit-count" key={item.monthNumber}>
                        <div className="percentage">{moment(item.monthNumber, 'M').format('MMMM')}</div>
                        <div className="people">
                            <div className="primary-text">{formatCurrency(item.invested)}</div>
                            <div className="secondary-text">{formatCurrency(item.withdrawn)}</div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default EmployeeWidget;
