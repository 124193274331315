import moment from 'moment';

export const inboxColors: { [key: string]: string } = {
    MARKETING: '#85c134',
    NEWS: '#3fc2c6',
    INFORMATION: '#3f7fc6',
    WEBINAR: '#6f3fc6',
};

export const shortMonthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const formatPhoneNumber = (phoneNumber: string): string => {
    let formatedPhone = phoneNumber.replace(/[^0-9+]/g, '');
    if (formatedPhone.startsWith('00')) formatedPhone = formatedPhone.replace('00', '+');
    if (formatedPhone.startsWith('09') || formatedPhone.startsWith('02')) formatedPhone = formatedPhone.replace('0', '+421');
    return formatedPhone;
};

export const accessNestedProperty = (object: Record<string, any>, propertyNames: string[]): any => {
    return propertyNames.reduce<any>((acc, propertyName) => (acc instanceof Object ? acc[propertyName] : undefined), object);
};

export const isObjectEmpty = (object?: Record<string, unknown>): boolean => {
    return object ? Object.keys(object).length === 0 : false;
};

export const generateShortUserName = (firstname?: string, lastname?: string): string => {
    return `${firstname?.toUpperCase()?.[0] ?? ''}${lastname?.toUpperCase()?.[0] ?? ''}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// if clearEmptyObjects is true, empty object properties are deleted ( property === {} )
export const clearObjectProperties = <T extends Record<string, any>>(object: T, clearEmptyObjects?: boolean): Partial<T> => {
    const newObject: Partial<T> = {};
    Object.keys(object).forEach((key) =>
        !!object[key] && (clearEmptyObjects ? Object.keys(object[key]).length !== 0 : true) ? (newObject[key as keyof T] = object[key]) : null,
    );
    return newObject;
};

const currencyNumberFormat = new Intl.NumberFormat('sk-SK', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
});
const percentNumberFormat = new Intl.NumberFormat('sk-SK', {
    style: 'percent',
    maximumFractionDigits: 2,
});

export const formatCurrency = (value?: number): string => {
    if (!value) return '0€';
    return currencyNumberFormat.format(value);
};

export const formatPercent = (value?: number, fallback?: string): string => {
    if (!value && value !== 0) {
        return fallback || '-';
    }

    return percentNumberFormat.format(value / 100);
};

export const validateIban = (value: string): boolean => {
    const trimmedValue = value.replace(/ /g, '');
    const rearrange = trimmedValue.substring(4, trimmedValue.length) + trimmedValue.substring(0, 4);
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const alphaMap: { [key: string]: number } = {};
    const number: number[] = [];

    alphabet.forEach((value, index) => {
        alphaMap[value] = index + 10;
    });

    rearrange.split('').forEach((value, index) => {
        number[index] = alphaMap[value] || Number(value);
    });

    return modulo(number.join('').toString(), 97) === 1;
};

const modulo = (aNumStr: string, aDiv: number) => {
    let tmp = '';
    for (let i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i);
        const r = Number(tmp) % aDiv;
        tmp = r.toString();
    }
    return Number(tmp) / 1;
};

export function dateFromFormatToIso(value?: string, format = 'DD.MM.YYYY HH:mm:ss', includeDay?: boolean): string {
    if (includeDay) {
        return moment(value, format).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISOString();
    } else {
        return moment(value, format).utcOffset(0, true).toISOString();
    }
}

export function dateFromIsoToFormat(value?: string, format = 'DD.MM.YYYY HH:mm:ss'): string {
    return moment(value).utc().format(format);
}

type RefType<T> = ((instance: T | null) => void) | React.MutableRefObject<T | null> | null;
export const combineRefs = <T>(refs: RefType<T>[]) => {
    return (value: T | null): void => {
        refs.forEach((ref: RefType<T>) => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref != null) {
                ref.current = value;
            }
        });
    };
};

export function addMonths(date: Date, months: number): Date {
    date.setMonth(date.getMonth() + months);
    return date;
}

// export const userRoleList = (roles?: RolesEnum[]) => {
//     if (!roles) return [];

//     switch (role) {
//         case RolesEnum.ADMIN:
//             return [RolesEnum.ADMIN];

//         case RolesEnum.EMPLOYEE:
//             return [RolesEnum.EMPLOYEE];

//         case RolesEnum.USER:
//             return [RolesEnum.USER];

//         case RolesEnum.NETWORKER:
//             return [RolesEnum.NETWORKER, RolesEnum.USER];

//         default:
//             return [];
//     }
// };

export const countriesList = [
    { code: 'PR', phone: '+1', country: ' Puerto Rico' },
    { code: 'PS', phone: '+970', country: ' Palestine' },
    { code: 'PT', phone: '+351', country: ' Portugal' },
    { code: 'PW', phone: '+680', country: ' Palau' },
    { code: 'PY', phone: '+595', country: ' Paraguay' },
    { code: 'QA', phone: '+974', country: ' Qatar' },
    { code: 'AC', phone: '+247', country: ' AC' },
    { code: 'AD', phone: '+376', country: ' Andorra' },
    { code: 'AE', phone: '+971', country: ' United Arab Emirates' },
    { code: 'AF', phone: '+93', country: ' Afghanistan' },
    { code: 'AG', phone: '+1', country: ' Antigua and Barbuda' },
    { code: 'AI', phone: '+1', country: ' Anguilla' },
    { code: 'AL', phone: '+355', country: ' Albania' },
    { code: 'AM', phone: '+374', country: ' Armenia' },
    { code: 'AO', phone: '+244', country: ' Angola' },
    { code: 'AR', phone: '+54', country: ' Argentina' },
    { code: 'AS', phone: '+1', country: ' American Samoa' },
    { code: 'AT', phone: '+43', country: ' Austria' },
    { code: 'RE', phone: '+262', country: ' Reunion' },
    { code: 'AU', phone: '+61', country: ' Australia' },
    { code: 'AW', phone: '+297', country: ' Aruba' },
    { code: 'AX', phone: '+358', country: ' Åland Islands' },
    { code: 'AZ', phone: '+994', country: ' Azerbaijan' },
    { code: 'RO', phone: '+40', country: ' Romania' },
    { code: 'BA', phone: '+387', country: ' Bosnia and Herzegovina' },
    { code: 'BB', phone: '+1', country: ' Barbados' },
    { code: 'RS', phone: '+381', country: ' Serbia' },
    { code: 'BD', phone: '+880', country: ' Bangladesh' },
    { code: 'RU', phone: '+7', country: ' Russia' },
    { code: 'BE', phone: '+32', country: ' Belgium' },
    { code: 'BF', phone: '+226', country: ' Burkina Faso' },
    { code: 'RW', phone: '+250', country: ' Rwanda' },
    { code: 'BG', phone: '+359', country: ' Bulgaria' },
    { code: 'BH', phone: '+973', country: ' Bahrain' },
    { code: 'BI', phone: '+257', country: ' Burundi' },
    { code: 'BJ', phone: '+229', country: ' Benin' },
    { code: 'BL', phone: '+590', country: ' Saint Barthélemy' },
    { code: 'BM', phone: '+1', country: ' Bermuda' },
    { code: 'BN', phone: '+673', country: ' Brunei' },
    { code: 'BO', phone: '+591', country: ' Bolivia' },
    { code: 'SA', phone: '+966', country: ' Saudi Arabia' },
    { code: 'BQ', phone: '+599', country: ' Bonaire, Sint Eustatius and Saba' },
    { code: 'SB', phone: '+677', country: ' Solomon Islands' },
    { code: 'BR', phone: '+55', country: ' Brazil' },
    { code: 'SC', phone: '+248', country: ' Seychelles' },
    { code: 'BS', phone: '+1', country: ' Bahamas' },
    { code: 'SD', phone: '+249', country: ' Sudan' },
    { code: 'SE', phone: '+46', country: ' Sweden' },
    { code: 'BT', phone: '+975', country: ' Bhutan' },
    { code: 'SG', phone: '+65', country: ' Singapore' },
    { code: 'BW', phone: '+267', country: ' Botswana' },
    { code: 'SH', phone: '+290', country: ' Saint Helena' },
    { code: 'SI', phone: '+386', country: ' Slovenia' },
    { code: 'SJ', phone: '+47', country: ' Svalbard And Jan Mayen' },
    { code: 'BY', phone: '+375', country: ' Belarus' },
    { code: 'SK', phone: '+421', country: ' Slovakia' },
    { code: 'BZ', phone: '+501', country: ' Belize' },
    { code: 'SL', phone: '+232', country: ' Sierra Leone' },
    { code: 'SM', phone: '+378', country: ' San Marino' },
    { code: 'SN', phone: '+221', country: ' Senegal' },
    { code: 'SO', phone: '+252', country: ' Somalia' },
    { code: 'CA', phone: '+1', country: ' Canada' },
    { code: 'SR', phone: '+597', country: ' Suriname' },
    { code: 'CC', phone: '+61', country: ' Cocos Islands' },
    { code: 'SS', phone: '+211', country: ' South Sudan' },
    { code: 'ST', phone: '+239', country: ' Sao Tome And Principe' },
    { code: 'CD', phone: '+243', country: ' The Democratic Republic Of Congo' },
    { code: 'CF', phone: '+236', country: ' Central African Republic' },
    { code: 'SV', phone: '+503', country: ' El Salvador' },
    { code: 'CG', phone: '+242', country: ' Congo' },
    { code: 'SX', phone: '+1', country: ' Sint Maarten (Dutch part)' },
    { code: 'CH', phone: '+41', country: ' Switzerland' },
    { code: 'CI', phone: '+225', country: " Côte d'Ivoire" },
    { code: 'SY', phone: '+963', country: ' Syria' },
    { code: 'SZ', phone: '+268', country: ' Swaziland' },
    { code: 'CK', phone: '+682', country: ' Cook Islands' },
    { code: 'CL', phone: '+56', country: ' Chile' },
    { code: 'CM', phone: '+237', country: ' Cameroon' },
    { code: 'CN', phone: '+86', country: ' China' },
    { code: 'CO', phone: '+57', country: ' Colombia' },
    { code: 'TA', phone: '+290', country: ' TA' },
    { code: 'TC', phone: '+1', country: ' Turks And Caicos Islands' },
    { code: 'CR', phone: '+506', country: ' Costa Rica' },
    { code: 'TD', phone: '+235', country: ' Chad' },
    { code: 'CU', phone: '+53', country: ' Cuba' },
    { code: 'TG', phone: '+228', country: ' Togo' },
    { code: 'CV', phone: '+238', country: ' Cape Verde' },
    { code: 'TH', phone: '+66', country: ' Thailand' },
    { code: 'CW', phone: '+599', country: ' Curaçao' },
    { code: 'CX', phone: '+61', country: ' Christmas Island' },
    { code: 'CY', phone: '+357', country: ' Cyprus' },
    { code: 'TJ', phone: '+992', country: ' Tajikistan' },
    { code: 'TK', phone: '+690', country: ' Tokelau' },
    { code: 'CZ', phone: '+420', country: ' Czech Republic' },
    { code: 'TL', phone: '+670', country: ' Timor-Leste' },
    { code: 'TM', phone: '+993', country: ' Turkmenistan' },
    { code: 'TN', phone: '+216', country: ' Tunisia' },
    { code: 'TO', phone: '+676', country: ' Tonga' },
    { code: 'TR', phone: '+90', country: ' Turkey' },
    { code: 'TT', phone: '+1', country: ' Trinidad and Tobago' },
    { code: 'DE', phone: '+49', country: ' Germany' },
    { code: 'TV', phone: '+688', country: ' Tuvalu' },
    { code: 'TW', phone: '+886', country: ' Taiwan' },
    { code: 'DJ', phone: '+253', country: ' Djibouti' },
    { code: 'TZ', phone: '+255', country: ' Tanzania' },
    { code: 'DK', phone: '+45', country: ' Denmark' },
    { code: 'DM', phone: '+1', country: ' Dominica' },
    { code: 'DO', phone: '+1', country: ' Dominican Republic' },
    { code: 'UA', phone: '+380', country: ' Ukraine' },
    { code: 'UG', phone: '+256', country: ' Uganda' },
    { code: 'DZ', phone: '+213', country: ' Algeria' },
    { code: 'US', phone: '+1', country: ' United States' },
    { code: 'EC', phone: '+593', country: ' Ecuador' },
    { code: 'EE', phone: '+372', country: ' Estonia' },
    { code: 'EG', phone: '+20', country: ' Egypt' },
    { code: 'EH', phone: '+212', country: ' Western Sahara' },
    { code: 'UY', phone: '+598', country: ' Uruguay' },
    { code: 'UZ', phone: '+998', country: ' Uzbekistan' },
    { code: 'VA', phone: '+39', country: ' Vatican' },
    { code: 'VC', phone: '+1', country: ' Saint Vincent And The Grenadines' },
    { code: 'ER', phone: '+291', country: ' Eritrea' },
    { code: 'ES', phone: '+34', country: ' Spain' },
    { code: 'VE', phone: '+58', country: ' Venezuela' },
    { code: 'ET', phone: '+251', country: ' Ethiopia' },
    { code: 'VG', phone: '+1', country: ' British Virgin Islands' },
    { code: 'VI', phone: '+1', country: ' U.S. Virgin Islands' },
    { code: 'VN', phone: '+84', country: ' Vietnam' },
    { code: 'VU', phone: '+678', country: ' Vanuatu' },
    { code: 'FI', phone: '+358', country: ' Finland' },
    { code: 'FJ', phone: '+679', country: ' Fiji' },
    { code: 'FK', phone: '+500', country: ' Falkland Islands' },
    { code: 'FM', phone: '+691', country: ' Micronesia' },
    { code: 'FO', phone: '+298', country: ' Faroe Islands' },
    { code: 'FR', phone: '+33', country: ' France' },
    { code: 'WF', phone: '+681', country: ' Wallis And Futuna' },
    { code: 'GA', phone: '+241', country: ' Gabon' },
    { code: 'GB', phone: '+44', country: ' United Kingdom' },
    { code: 'WS', phone: '+685', country: ' Samoa' },
    { code: 'GD', phone: '+1', country: ' Grenada' },
    { code: 'GE', phone: '+995', country: ' Georgia' },
    { code: 'GF', phone: '+594', country: ' French Guiana' },
    { code: 'GG', phone: '+44', country: ' Guernsey' },
    { code: 'GH', phone: '+233', country: ' Ghana' },
    { code: 'GI', phone: '+350', country: ' Gibraltar' },
    { code: 'GL', phone: '+299', country: ' Greenland' },
    { code: 'GM', phone: '+220', country: ' Gambia' },
    { code: 'GN', phone: '+224', country: ' Guinea' },
    { code: 'GP', phone: '+590', country: ' Guadeloupe' },
    { code: 'GQ', phone: '+240', country: ' Equatorial Guinea' },
    { code: 'GR', phone: '+30', country: ' Greece' },
    { code: 'GT', phone: '+502', country: ' Guatemala' },
    { code: 'GU', phone: '+1', country: ' Guam' },
    { code: 'GW', phone: '+245', country: ' Guinea-Bissau' },
    { code: 'GY', phone: '+592', country: ' Guyana' },
    { code: 'XK', phone: '+383', country: ' XK' },
    { code: 'HK', phone: '+852', country: ' Hong Kong' },
    { code: 'HN', phone: '+504', country: ' Honduras' },
    { code: 'HR', phone: '+385', country: ' Croatia' },
    { code: 'YE', phone: '+967', country: ' Yemen' },
    { code: 'HT', phone: '+509', country: ' Haiti' },
    { code: 'HU', phone: '+36', country: ' Hungary' },
    { code: 'ID', phone: '+62', country: ' Indonesia' },
    { code: 'YT', phone: '+262', country: ' Mayotte' },
    { code: 'IE', phone: '+353', country: ' Ireland' },
    { code: 'IL', phone: '+972', country: ' Israel' },
    { code: 'IM', phone: '+44', country: ' Isle Of Man' },
    { code: 'IN', phone: '+91', country: ' India' },
    { code: 'IO', phone: '+246', country: ' British Indian Ocean Territory' },
    { code: 'ZA', phone: '+27', country: ' South Africa' },
    { code: 'IQ', phone: '+964', country: ' Iraq' },
    { code: 'IR', phone: '+98', country: ' Iran' },
    { code: 'IS', phone: '+354', country: ' Iceland' },
    { code: 'IT', phone: '+39', country: ' Italy' },
    { code: 'ZM', phone: '+260', country: ' Zambia' },
    { code: 'JE', phone: '+44', country: ' Jersey' },
    { code: 'ZW', phone: '+263', country: ' Zimbabwe' },
    { code: 'JM', phone: '+1', country: ' Jamaica' },
    { code: 'JO', phone: '+962', country: ' Jordan' },
    { code: 'JP', phone: '+81', country: ' Japan' },
    { code: 'KE', phone: '+254', country: ' Kenya' },
    { code: 'KG', phone: '+996', country: ' Kyrgyzstan' },
    { code: 'KH', phone: '+855', country: ' Cambodia' },
    { code: 'KI', phone: '+686', country: ' Kiribati' },
    { code: 'KM', phone: '+269', country: ' Comoros' },
    { code: 'KN', phone: '+1', country: ' Saint Kitts And Nevis' },
    { code: 'KP', phone: '+850', country: ' North Korea' },
    { code: 'KR', phone: '+82', country: ' South Korea' },
    { code: 'KW', phone: '+965', country: ' Kuwait' },
    { code: 'KY', phone: '+1', country: ' Cayman Islands' },
    { code: 'KZ', phone: '+7', country: ' Kazakhstan' },
    { code: 'LA', phone: '+856', country: ' Laos' },
    { code: 'LB', phone: '+961', country: ' Lebanon' },
    { code: 'LC', phone: '+1', country: ' Saint Lucia' },
    { code: 'LI', phone: '+423', country: ' Liechtenstein' },
    { code: 'LK', phone: '+94', country: ' Sri Lanka' },
    { code: 'LR', phone: '+231', country: ' Liberia' },
    { code: 'LS', phone: '+266', country: ' Lesotho' },
    { code: 'LT', phone: '+370', country: ' Lithuania' },
    { code: 'LU', phone: '+352', country: ' Luxembourg' },
    { code: 'LV', phone: '+371', country: ' Latvia' },
    { code: 'LY', phone: '+218', country: ' Libya' },
    { code: 'MA', phone: '+212', country: ' Morocco' },
    { code: 'MC', phone: '+377', country: ' Monaco' },
    { code: 'MD', phone: '+373', country: ' Moldova' },
    { code: 'ME', phone: '+382', country: ' Montenegro' },
    { code: 'MF', phone: '+590', country: ' Saint Martin' },
    { code: 'MG', phone: '+261', country: ' Madagascar' },
    { code: 'MH', phone: '+692', country: ' Marshall Islands' },
    { code: 'MK', phone: '+389', country: ' Macedonia' },
    { code: 'ML', phone: '+223', country: ' Mali' },
    { code: 'MM', phone: '+95', country: ' Myanmar' },
    { code: 'MN', phone: '+976', country: ' Mongolia' },
    { code: 'MO', phone: '+853', country: ' Macao' },
    { code: 'MP', phone: '+1', country: ' Northern Mariana Islands' },
    { code: 'MQ', phone: '+596', country: ' Martinique' },
    { code: 'MR', phone: '+222', country: ' Mauritania' },
    { code: 'MS', phone: '+1', country: ' Montserrat' },
    { code: 'MT', phone: '+356', country: ' Malta' },
    { code: 'MU', phone: '+230', country: ' Mauritius' },
    { code: 'MV', phone: '+960', country: ' Maldives' },
    { code: 'MW', phone: '+265', country: ' Malawi' },
    { code: 'MX', phone: '+52', country: ' Mexico' },
    { code: 'MY', phone: '+60', country: ' Malaysia' },
    { code: 'MZ', phone: '+258', country: ' Mozambique' },
    { code: 'NA', phone: '+264', country: ' Namibia' },
    { code: 'NC', phone: '+687', country: ' New Caledonia' },
    { code: 'NE', phone: '+227', country: ' Niger' },
    { code: 'NF', phone: '+672', country: ' Norfolk Island' },
    { code: 'NG', phone: '+234', country: ' Nigeria' },
    { code: 'NI', phone: '+505', country: ' Nicaragua' },
    { code: 'NL', phone: '+31', country: ' Netherlands' },
    { code: 'NO', phone: '+47', country: ' Norway' },
    { code: 'NP', phone: '+977', country: ' Nepal' },
    { code: 'NR', phone: '+674', country: ' Nauru' },
    { code: 'NU', phone: '+683', country: ' Niue' },
    { code: 'NZ', phone: '+64', country: ' New Zealand' },
    { code: 'OM', phone: '+968', country: ' Oman' },
    { code: 'PA', phone: '+507', country: ' Panama' },
    { code: 'PE', phone: '+51', country: ' Peru' },
    { code: 'PF', phone: '+689', country: ' French Polynesia' },
    { code: 'PG', phone: '+675', country: ' Papua New Guinea' },
    { code: 'PH', phone: '+63', country: ' Philippines' },
    { code: 'PK', phone: '+92', country: ' Pakistan' },
    { code: 'PL', phone: '+48', country: ' Poland' },
    { code: 'PM', phone: '+508', country: ' Saint Pierre And Miquelon' },
];
