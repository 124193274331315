import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import clientService from '../../../service/clientService';
import { formatCurrency } from '../../../utils/utils';
import LoadingIndicator from '../../../components/loading-indicator';

const Reinvest: React.FC = () => {
    const { addToast } = useToasts();
    const { t } = useTranslation();

    const [value, setValue] = useState<number>(0);
    const [amount, setAmount] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);

    const reinvestAmount = useMemo(() => {
        return +(amount * (value / 100)).toFixed(2);
    }, [value, amount]);

    const getAmount = () => {
        clientService.getAvailableProfits().then((res) => {
            setAmount(res.data.bonusAmount);
        });
    };

    useEffect(() => {
        getAmount();
    }, []);

    const withdraw = () => {
        setLoading(true);
        clientService
            .reinvestProfits(value)
            .then(() => {
                addToast(t('toast.reinvestSuccess'));
                getAmount();
                setValue(0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <LoadingIndicator visible={loading} />
            <div className="invest-page-container">
                <div className="invest-grid-container">
                    <div className="invest-widget">
                        <div>
                            <div className="d-flex justify-content-between">
                                <div className="invest-label">{t('withdrawal.myAccount')}</div>
                                <div className="invest-label">
                                    {t('withdrawal.totalProfit')} : {formatCurrency(amount)}
                                </div>
                                <div className="d-flex justify-content-end">
                                    <span className="my-account-desc">{t('invest.reinvestQuestion')}</span>
                                </div>
                            </div>
                            <div className="invest-range-input">
                                <input
                                    disabled={amount < 10}
                                    type="range"
                                    value={value}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setValue(+e.target.value);
                                    }}
                                    className="form-range"
                                    min="0"
                                    max={100}
                                    step="1"
                                />
                            </div>
                        </div>
                        <div className="invest-btn-wrapper">
                            <button disabled={reinvestAmount < 10} onClick={withdraw}>
                                {t('invest.reinvest')}
                            </button>
                        </div>
                        <div className="d-flex justify-content-end">
                            <span className="my-account-money">{formatCurrency(reinvestAmount)}</span>
                        </div>
                    </div>
                </div>
                {/* <div className= ">
                    <div>
                        <div className="primary-text">{t('withdrawal.totalProfit')}</div>
                        <div className="secondary-text">{user?.balance} €</div>
                    </div>
                    <div className="last-deposit">
                        <div className="primary-text">{t('withdrawal.lastWithdrawal')}</div>
                        <div className="secondary-text">0€</div>
                    </div>
                    <div>
                        <div className="primary-text">{t('withdrawal.info')}</div>
                        <div className="info-text">{t('withdrawal.infoText')}</div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default Reinvest;
