import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useUser } from '../../../context/user-context';
import usePrepareLink from '../../../hooks/use-prepare-link';
import Routes from '../../../routes/routes';
import clientService from '../../../service/clientService';
import employeeService from '../../../service/employeeService';
import RefferalsView from './refferals-view';

export type ReferralFormType = FieldValues & {
    email: string;
};

const Refferals: React.FC = () => {
    const { user } = useUser();
    const { addToast } = useToasts();
    const history = useHistory();
    const { t } = useTranslation();

    const {
        register,
        getValues,
        formState: { errors },
        trigger,
        reset,
    } = useForm<ReferralFormType>();

    const [loading, setLoading] = useState<boolean>(false);
    const [usersCount, setUsersCount] = useState<number>(0);
    const refferalLink = `${window.location.origin}/register-client?token=${user?.referral}`;

    const sendLink = usePrepareLink({
        to: Routes.SEND_REFERAL,
        isRelativePath: true,
    });

    useEffect(() => {
        clientService.getTotalUsers().then((res) => {
            setUsersCount(res.data.count);
        });
    }, []);

    const sendInviteLink = () => {
        setLoading(true);
        const data = getValues();
        employeeService
            .inviteClient(data.email)
            .then(onSuccess)
            .finally(() => {
                setLoading(false);
                reset();
            });
        function onSuccess() {
            addToast(t('toast.referalLinkSended'));
            history.goBack();
        }
    };

    const coppyLink = () => {
        navigator.clipboard.writeText(refferalLink);
        addToast(t('toast.referalLinkCoppied'));
    };

    const openLinkModal = () => {
        history.push(sendLink);
    };

    return (
        <>
            <RefferalsView
                usersCount={usersCount}
                loading={loading}
                refferalLink={refferalLink}
                errors={errors}
                register={register}
                trigger={trigger}
                onClose={history.goBack}
                onCoppyLink={coppyLink}
                onSendLink={openLinkModal}
                handleSend={sendInviteLink}
            />
        </>
    );
};

export default Refferals;
