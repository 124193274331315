import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { ClientInvestmentItemType, ClientStatisticItemType, ClientStatisticPortfolioType } from '../../../../models/api/dashboard-model';
import { formatCurrency, generateShortUserName } from '../../../../utils/utils';
import UserLogo from './../../../../components/user-logo';
import { ColorResult, PhotoshopPicker } from 'react-color';

type PropsType = {
    item: ClientStatisticItemType;
    eventKey: string;
    portfolios: ClientStatisticPortfolioType[];
    investments: ClientInvestmentItemType[];
    loadPortfolios: (id: number) => void;
    loadInvestments: (id: number) => void;
    updateColor: (color: string, id: number) => void;
};

const ClientItem: React.FC<PropsType> = ({ item, eventKey, portfolios, investments, loadPortfolios, loadInvestments, updateColor }) => {
    const { t } = useTranslation();

    const [colorPicker, setColorPicker] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');

    return (
        <div className="affiliate-item">
            <div
                className="affilate-color"
                style={{ backgroundColor: item.adminColor || '#cecece' }}
                // onClick={() => {
                //     setColorPicker(true);
                // }}
            ></div>
            <div className="item-info-container">
                <div className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.name, item.surname)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{`${item.name} ${item.surname}`}</div>
                        <div className="secondary-text">{item.username}</div>
                        <div className="secondary-text">{item.parentLabel}</div>
                    </div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.totalInvest)}</div>
                    <div className="secondary-text">{formatCurrency(item.lastInvest?.amount)}</div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.lastInvest?.type ? t(`enums.investType.${item.lastInvest?.type}`) : ''}</div>
                    <div className="secondary-text">{moment(item.lastInvest?.createdAt).format('DD.MM.YYYY')}</div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.totalInvest)}</div>
                    <div className="secondary-text">{formatCurrency(item.agioAmount)}</div>
                </div>
                {/* <div></div> */}
                <div className="affilate-action flex-column">
                    <ContextAwareToggle eventKey={eventKey} callback={() => loadInvestments(item.id)} />
                    {!item.isEnabled && item.disabledFrom != null && (
                        <>
                            <div className="secondary-text bg-danger font-weight-bold text-uppercase text-white p-1 m-1 rounded">Disabled</div>
                            <div className="primary-text">{moment(item.disabledFrom).format('DD.MM.YYYY')}</div>
                        </>
                    )}
                </div>
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <Accordion defaultActiveKey="0">
                    <div className="item-portfolio-container">
                        {investments.map((item, index) => (
                            <>
                                <div className="item-info-container" key={index}>
                                    <div className="affilate-amount">
                                        <div className="secondary-text align-items-center">{`0${index + 1}`}</div>
                                    </div>
                                    <div className="affilate-amount">
                                        <div className="primary-text">{formatCurrency(item.amount)}</div>
                                        <div className="secondary-text">{}</div>
                                    </div>
                                    <div className="affilate-amount">
                                        <div className="primary-text">{item.type ? t(`enums.investType.${item.type}`) : ''}</div>
                                        <div className="secondary-text">{moment(item.createdAt).format('DD.MM.YYYY')}</div>
                                    </div>
                                    <div className="affilate-amount">
                                        <div className="secondary-text align-items-center">
                                            <ContextArrowAwareToggle eventKey={`port${item.id}`} callback={() => loadPortfolios(item.id)} />
                                        </div>
                                    </div>
                                </div>
                                <Accordion.Collapse eventKey={`port${item.id}`}>
                                    <>
                                        {portfolios.map((port, i) => (
                                            <div className="item-info-container p-0" key={i}>
                                                <div className="affilate-amount"></div>
                                                <div className="affilate-amount">
                                                    <div className="secondary-text">{port.position.type}</div>
                                                </div>
                                                <div className="affilate-amount">
                                                    <div className="secondary-text">{formatCurrency(port.initialAmount)}</div>
                                                </div>
                                            </div>
                                        ))}

                                        {portfolios.length === 0 && (
                                            <div className="portfolio-empty">
                                                <span>{t('affilate.empty-list')}</span>
                                            </div>
                                        )}
                                    </>
                                </Accordion.Collapse>
                            </>
                        ))}
                        {investments.length === 0 && (
                            <div className="portfolio-empty">
                                <span>{t('affilate.empty-list')}</span>
                            </div>
                        )}
                    </div>
                </Accordion>
            </Accordion.Collapse>
            {colorPicker && (
                <div className="color-picker">
                    <PhotoshopPicker
                        color={value}
                        onChange={(color: ColorResult) => setValue(color.hex)}
                        onAccept={() => {
                            updateColor(value, item.id);
                            setColorPicker(false);
                        }}
                        onCancel={() => {
                            setColorPicker(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | string;
};

function ContextAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" onClick={decoratedOnClick}>
            {isCurrentEventKey ? 'Hide' : 'Show'}
        </button>
    );
}

function ContextArrowAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div role="button" className="p-2" onClick={decoratedOnClick}>
            {isCurrentEventKey ? <ChevronUp /> : <ChevronDown />}
        </div>
    );
}

export default ClientItem;
