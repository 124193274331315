import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import {
    ClientSimplified,
    EmployeeStatisticType,
    InvestmentResponseType,
    PortfolioHistoryType,
    ProfitsResponseType,
    UserHistoryResponseType,
    WithdrawalRequestsResponseType,
    WithdrawalRequestType,
} from '../../../models/api/dashboard-model';
import { HistoryEnum } from '../../../models/enums';
import employeeService from '../../../service/employeeService';
import DashboardChart from '../../client/dashboard/components/dashboard-chart';
import DashboardRefferal from '../../client/dashboard/components/dashboard-refferal';
import DashboradTotal from '../../client/dashboard/components/dashboard-total';
import InvestHistory from '../../client/invest/components/invest-history';
import DashboardEmpPortfolios from './../../client/dashboard/components/dashboard-emp-portfolios';
import WithdrawHistory from '../../client/invest/components/withdraw-history';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { ProfitIcon } from '../../../assets/images';
import RadialSeparators from '../../../components/RadioSeparators';
import { formatPercent, formatCurrency } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';

const Dashboard: React.FC = () => {
    const { t } = useTranslation();

    // const [statistics, setStatistics] = useState<EmployeeStatisticType>();

    // useEffect(() => {
    //     employeeService.getStatistics().then(({ data }: AxiosResponse<EmployeeStatisticType>) => {
    //         setStatistics(data);
    //     });
    // }, []);

    const [profits, setProfits] = useState<ProfitsResponseType>();
    const [investment, setInvestment] = useState<InvestmentResponseType>();
    const [history, setHistory] = useState<PortfolioHistoryType>();
    const [client, setClient] = useState<ClientSimplified>();
    const [statistic, setStatistic] = useState<EmployeeStatisticType>();
    const [withdrawalRequests, setWithdrawalRequests] = useState<WithdrawalRequestType[]>([]);

    const profitWithdrawal = useMemo(() => {
        return withdrawalRequests.filter((i) => i.type === 'ACTIVE_PROFIT');
    }, [withdrawalRequests]);

    const bonusesWithdrawal = useMemo(() => {
        return withdrawalRequests.filter((i) => i.type === 'REFERRAL_BONUSES');
    }, [withdrawalRequests]);

    const loadHistory = useCallback(() => {
        client &&
            employeeService.getHistory(client.username, HistoryEnum.YEAR).then(({ data }: AxiosResponse<UserHistoryResponseType>) => {
                const historyValue = { ...data.history, BALANCE: data.currentBalanceHistory };
                setHistory(historyValue);
            });
    }, [client]);

    useEffect(() => {
        if (client) {
            employeeService.getProfits(client.username).then(({ data }: AxiosResponse<ProfitsResponseType>) => {
                setProfits(data);
            });
            employeeService.getInvestment(client.username).then(({ data }: AxiosResponse<InvestmentResponseType>) => {
                setInvestment(data);
            });
            employeeService.getWithdrawal(client.username).then(({ data }: AxiosResponse<WithdrawalRequestsResponseType>) => {
                setWithdrawalRequests(data.requests.content);
            });
            loadHistory();
        } else {
            setProfits(undefined);
            setInvestment(undefined);
            setHistory(undefined);
        }
    }, [client, loadHistory]);

    useEffect(() => {
        employeeService.getStatistics().then((res) => {
            setStatistic(res.data);
        });
    }, []);

    const summTotalProfit = useMemo(() => {
        if (profits) {
            const prof = profits.fromPortfolio.ACTIVE + profits.fromPortfolio.ESTABLISHED + profits.fromPortfolio.STARTUP;
            return prof;
        }
        return 0;
    }, [profits]);

    return (
        // <div className="emp-dashboard-container">
        //     <div className="admin-statisics-grid-container empl-grid">
        //         <div className="statistic-item">
        //             <div className="primaryLabel">{formatAmount(statistics?.totalInvested?.toFixed(2) ?? '0')}</div>
        //             <div className="secondaryLabel">{t('dashboard.totalInvested')}</div>
        //         </div>
        //         <div className="statistic-item">
        //             <div className="primaryLabel">{formatAmount(statistics?.totalProfit?.toFixed(2) ?? '0')}</div>
        //             <div className="secondaryLabel">{t('dashboard.totalProfit')}</div>
        //         </div>
        //     </div>
        // </div>
        <>
            <div className="dashboard-container">
                <DashboardEmpPortfolios
                    data={profits}
                    investment={investment}
                    client={client}
                    handleUsername={(value?: ClientSimplified) => setClient(value)}
                    employeeData={statistic?.currentBalance}
                />
                <DashboardChart history={history} />
                <DashboradTotal
                    total={summTotalProfit}
                    firstWeekTradingInvestmentsAmount={investment?.firstWeekTradingInvestmentsAmount}
                    pendingInvestmentsAmount={investment?.pendingInvestmentsAmount}
                />
                {/* {profits && <DashboardRefferal refferalAmount={profits.fromReferrals} />} */}
            </div>
            <Row className="container-wrapper invest-widgets-grid-container d-flex  ">
                {/* <InvestChart /> */}
                <Col xs={12} lg={3}>
                    <InvestHistory investmentHistory={investment?.history} />
                </Col>
                <Col xs={12} lg={3}>
                    <WithdrawHistory data={profitWithdrawal} title={'Withdrawal Active Profits'} />
                </Col>
                <Col xs={12} lg={3}>
                    <WithdrawHistory data={bonusesWithdrawal} title={'Withdrawal Bonuses'} />
                </Col>
                <Col xs={12} lg={3}>
                    <Card className="mt-2">
                        <Card.Header className="d-flex align-items-center justify-content-between header-trader">
                            <div className="startup-title">{t('dashboard.profit')}</div>
                            <img src={ProfitIcon} alt="" />
                            <div>{/* <TraderItem /> */}</div>
                        </Card.Header>
                        <Card.Body>
                            <div className="profit-item ">
                                <div className="profit-progress">
                                    <CircularProgressbarWithChildren
                                        value={profits?.termBasedProfitData.shortPercents ?? 0}
                                        text={formatPercent(profits?.termBasedProfitData.shortPercents)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            strokeLinecap: 'butt',
                                        })}
                                    >
                                        <RadialSeparators
                                            count={35}
                                            style={{
                                                background: 'black',
                                                width: '5px',
                                                // This needs to be equal to props.strokeWidth
                                                height: `${10}%`,
                                            }}
                                        />
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div className="profit-text-wrapper">
                                    <div className="profit-text-primary">{formatCurrency(profits?.termBasedProfitData.shortProfits)}</div>
                                    <div className="profit-text-secondary">{t('dashboard.shortProfit')}</div>
                                </div>
                            </div>
                            <div className="profit-item ">
                                <div className="profit-progress">
                                    <CircularProgressbarWithChildren
                                        value={profits?.termBasedProfitData.longProfits ?? 0}
                                        text={formatPercent(profits?.termBasedProfitData.longPercents)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            strokeLinecap: 'butt',
                                        })}
                                    >
                                        <RadialSeparators
                                            count={35}
                                            style={{
                                                background: 'black',
                                                width: '5px',
                                                // This needs to be equal to props.strokeWidth
                                                height: `${10}%`,
                                            }}
                                        />
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div className="profit-text-wrapper">
                                    <div className="profit-text-primary">{formatCurrency(profits?.termBasedProfitData.longProfits)}</div>
                                    <div className="profit-text-secondary">{t('dashboard.longProfit')}</div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Dashboard;
