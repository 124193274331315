import moment from 'moment';
import React, { useContext } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ApproveIcon, DeclinedIcon, WaitingIcon } from '../../../../assets/images';
import { PayoutRequestType } from '../../../../models/api/dashboard-model';
import { PayoutStatusEnum } from '../../../../models/enums';
import { formatCurrency, generateShortUserName } from '../../../../utils/utils';
import UserLogo from './../../../../components/user-logo';

type PropsType = {
    item: PayoutRequestType;
    eventKey: string;
    approveWithdraw: () => void;
    declineWithdraw: () => void;
};

const icons: { [key: string]: string } = {
    APPROVED: ApproveIcon,
    DECLINED: DeclinedIcon,
    PENDING: WaitingIcon,
};

const RequestItem: React.FC<PropsType> = ({ item, eventKey, approveWithdraw, declineWithdraw }) => {
    const { t } = useTranslation();
    return (
        <div className="affiliate-item">
            <div className="item-info-container">
                <div className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.user.name, item.user.surname)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{`${item.user.name} ${item.user.surname}`}</div>
                        <div className="secondary-text">{item.user.username}</div>
                        <div className="secondary-text">{moment(item.createdAt).format('DD.MM.yyyy')}</div>
                    </div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.user.email}</div>
                    <div className="secondary-text">{item.user.phone}</div>
                    <div className="secondary-text">{t(`enums.paymentType.${item.type}`)}</div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.amount)}</div>
                    <div className="secondary-text">{t(`enums.payment.${item.status}`)}</div>
                    <div className="secondary-text">{moment(item.updatedAt).format('DD.MM.yyyy')}</div>
                </div>
                {/* <div></div> */}
                <div className="affilate-action">
                    <ContextAwareToggle eventKey={eventKey} />
                    <img className="withdraw-status" src={icons[item.status]} />
                </div>
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <div className="withdrawal-request-info-container">
                    <div>{`${t('withdrawal.iban')}: ${item.iban}`}</div>
                    <div>{`${t('withdrawal.usdt')}: ${item.cardNumber}`}</div>
                    <div></div>
                    <div className="withdrawal-request-action">
                        {item.status === PayoutStatusEnum.PENDING && (
                            <>
                                <button className="base-btn" onClick={approveWithdraw}>
                                    {t('common.approve')}
                                </button>
                                <button className="btn-border" onClick={declineWithdraw}>
                                    {t('common.decline')}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </Accordion.Collapse>
        </div>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | string;
};

function ContextAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" onClick={decoratedOnClick}>
            {isCurrentEventKey ? 'Hide' : 'Show'}
        </button>
    );
}

export default RequestItem;
