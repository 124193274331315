import React, { useEffect, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BaseInput from '../../../components/common/base-input';
import DatePicker from '../../../components/datePicker/datePicker';
import LoadingIndicator from '../../../components/loading-indicator';
import Paginator from '../../../components/paginator';
import { useDebounce } from '../../../hooks/use-debounce';
import {
    ClientInvestmentItemType,
    ClientInvestmentResponseType,
    ClientStatisticPortfolioType,
    ClientTotalResponseType,
    PagebleType,
    RegisteredUsersRequestType,
    RegisteredUserType,
} from '../../../models/api/dashboard-model';
import adminService from '../../../service/adminService';
import PeopleWidget from '../statistic/components/people-widget';
import RegisteredUserItem from './components/regiretered-user-item';

type SearchFormType = {
    date: string;
};

const RegisteredUsers: React.FC = () => {
    const { t } = useTranslation();

    const [users, setUsers] = useState<RegisteredUserType[]>([]);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [parentName, setParentName] = useState<string>('');
    const [pagination, setPagination] = useState<PagebleType<RegisteredUserType>>();
    const [investPagination, setInvestPagination] = useState<ClientInvestmentResponseType>();
    const [portfolios, setPortfolios] = useState<ClientStatisticPortfolioType[]>([]);
    const [investPageIndex, setInvestPageIndex] = useState<number>(0);
    const [investments, setInvestments] = useState<ClientInvestmentItemType[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [countryCode, setCountryCode] = useState<string>('SK');
    const [total, setTotal] = useState<ClientTotalResponseType>();
    const [widgetYear, setWidgetYear] = useState<number>(2021);

    const debounceNameSearch = useDebounce<string>(name.trim(), 600);
    const debounceEmailSearch = useDebounce<string>(email.trim(), 600);
    const debounceParentNameSearch = useDebounce<string>(parentName.trim(), 600);

    const {
        register,
        watch,
        formState: { errors },
        trigger,
        setValue,
    } = useForm<SearchFormType>();

    const date = watch('date');

    const loadRegisteredClients = (data: RegisteredUsersRequestType, page: number) => {
        setLoading(true);
        adminService
            .loadRegisteredClients(data, { page })
            .then((res) => {
                setUsers(res.data.users.content);
                setPagination(res.data.users);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        adminService.getClientTotal(countryCode, widgetYear).then((res) => {
            setTotal(res.data);
        });
    }, [countryCode, widgetYear]);

    const loadPortfolios = (id: number) => {
        setLoading(true);
        adminService
            .getClientPortfolios(id)
            .then((res) => setPortfolios(res.data))
            .finally(() => {
                setLoading(false);
            });
    };

    const loadInvestments = (userId: number, page: number) => {
        setLoading(true);
        adminService
            .getClientInvestments(userId, { size: 10, page })
            .then((res) => {
                setInvestPageIndex(page);
                setInvestments(res.data.content);
                setInvestPagination(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadRegisteredClients(
            { name: debounceNameSearch, parentName: debounceParentNameSearch, email: debounceEmailSearch, phone: '', registrationDate: date },
            pageIndex,
        );
    }, [pageIndex, debounceNameSearch, debounceEmailSearch, debounceParentNameSearch, date]);

    return (
        <>
            <LoadingIndicator visible={loading} />
            <div className="affiliate-grid-container client-container">
                <div className="search-wrapper mb-2">
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="nameSearch" className="form-label mb-2">
                            {t('common.nameSearch')}
                        </label>
                    </div>
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="emailSearch" className="form-label mb-2">
                            Email Search
                        </label>
                    </div>
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={parentName}
                            onChange={(e) => setParentName(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="parentNameSearch" className="form-label mb-2">
                            Parent Search
                        </label>
                    </div>
                    <div className="date-picker-wrapper">
                        <DatePicker setValue={setValue} maxDate={new Date()} name="date">
                            <BaseInput {...register('date')} errors={errors} trigger={trigger} type="text" maxLength={10} label={t('auth.date')} />
                        </DatePicker>
                    </div>
                </div>
                <Row>
                    <Col xs={9} md={9} lg={9}>
                        <Accordion defaultActiveKey="0">
                            {users.map((i, index) => (
                                <RegisteredUserItem
                                    key={index}
                                    eventKey={`key${index}`}
                                    item={i}
                                    investments={investments}
                                    pagination={investPagination}
                                    pageIndex={investPageIndex}
                                    portfolios={portfolios}
                                    loadPortfolios={loadPortfolios}
                                    loadInvestments={loadInvestments}
                                />
                            ))}
                        </Accordion>
                        <Paginator
                            pageIndex={pageIndex}
                            dataLength={pagination?.totalElements}
                            onPageChanged={(newPage: number) => {
                                setPageIndex(newPage);
                            }}
                            pageSize={20}
                        />
                    </Col>
                    <Col xs={3} md={3} lg={3}>
                        <PeopleWidget
                            totalRegisteredByMonth={total?.totalRegisteredByMonth}
                            code={countryCode}
                            handleCode={setCountryCode}
                            year={widgetYear}
                            handleYear={setWidgetYear}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default RegisteredUsers;
