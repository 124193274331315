import { faCheck, faExclamation, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ToastContainerProps, ToastProps } from 'react-toast-notifications';

const getToastProperties = (appearance: 'error' | 'info' | 'success' | 'warning') => {
    switch (appearance) {
        case 'error':
            return { icon: faTimes, color: 'bg-danger', textColor: 'text-white' };
        case 'warning':
            return {
                icon: faExclamation,
                color: 'bg-warning',
                textColor: 'text-dark',
            };
        default:
            return { icon: faCheck, color: 'bg-success', textColor: 'text-white' };
    }
};

export const CustomToast: React.FC<ToastProps> = ({ appearance, children, onDismiss }) => {
    const toastProperties = getToastProperties(appearance);
    return (
        <>
            <div
                className={`alert d-flex justify-content-between ${toastProperties.textColor} ${toastProperties.color}`}
                onClick={() => onDismiss()}
                style={{ cursor: 'pointer', fontSize: '1.2em' }}
            >
                <FontAwesomeIcon icon={toastProperties.icon} size="lg" className="mr-2" />
                <span>{children}</span>
            </div>
        </>
    );
};

export const CustomToastContainer: React.FC<ToastContainerProps> = (props) => (
    <div
        className={'custom-toast-container'}
        style={{
            position: 'fixed',
            top: '1rem',
            width: '100%',
            pointerEvents: 'none',
            zIndex: 2000,
        }}
    >
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xs-12 col-xl-8" style={{ pointerEvents: 'auto' }}>
                    {props.children}
                </div>
            </div>
        </div>
    </div>
);
