import React from 'react';
import { useTranslation } from 'react-i18next';

type PropsType = {
    onSend: () => void;
};

const AdminRefferalLinkWidget: React.FC<PropsType> = ({ onSend }) => {
    const { t } = useTranslation();
    return (
        <div className="link-container ">
            <div className="link-wrapper title-center">
                <div className="link-title">{t('refferals.refferalAdminText')}</div>
            </div>
            <div className="link-btns-group">
                <button className="btn-send m-0" onClick={onSend}>
                    {t('auth.send')}
                </button>
            </div>
        </div>
    );
};

export default AdminRefferalLinkWidget;
