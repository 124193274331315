import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { ColorResult, PhotoshopPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import Paginator from '../../../../components/paginator';
import { SaleInvestmentItemType, SaleInvestmentResponseType, SaleStatisticItemType } from '../../../../models/api/dashboard-model';
import { formatCurrency, generateShortUserName } from '../../../../utils/utils';
import UserLogo from './../../../../components/user-logo';

type PropsType = {
    item: SaleStatisticItemType;
    eventKey: string;
    investments: SaleInvestmentItemType[];
    pageIndex: number;
    pagination?: SaleInvestmentResponseType;
    loadInvestments: (id: number, page: number) => void;
    updateColor: (color: string, id: number) => void;
};

const SaleItem: React.FC<PropsType> = ({ item, eventKey, investments, pageIndex, pagination, loadInvestments, updateColor }) => {
    const { t } = useTranslation();

    const [colorPicker, setColorPicker] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');

    return (
        <div className="affiliate-item">
            <div
                className="affilate-color"
                style={{ backgroundColor: item.adminColor || '#cecece' }}
                onClick={() => {
                    setColorPicker(true);
                }}
            ></div>
            <div className="item-info-container">
                <div className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.name, item.surname)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{`${item.name} ${item.surname}`}</div>
                        <div className="secondary-text">{item.username}</div>
                        <div className="secondary-text">{}</div>
                    </div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.totalInvestedByClients)}</div>
                    <div className="secondary-text">{formatCurrency(item.totalBonusFromClients)}</div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.totalPaidOutBonuses)}</div>
                    <div className="secondary-text">{moment(new Date()).format('DD.MM.YYYY')}</div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.totalUnpaidBonuses)}</div>
                    <div className="secondary-text">{moment(new Date()).format('DD.MM.YYYY')}</div>
                </div>
                {/* <div></div> */}
                <div className="affilate-action">
                    <ContextAwareToggle eventKey={eventKey} callback={() => loadInvestments(item.id, 0)} />
                </div>
            </div>
            <Accordion.Collapse eventKey={eventKey}>
                <div className="item-portfolio-container">
                    {investments.map(({ user, totalInvested }, index) => (
                        <>
                            <div className="item-info-container two-items" key={index}>
                                <div className="affilate-amount">
                                    <div className="secondary-text align-items-center">{`${user.name} ${user.surname}`}</div>
                                </div>
                                <div className="affilate-amount">
                                    <div className="primary-text">{formatCurrency(totalInvested)}</div>
                                    <div className="secondary-text">{}</div>
                                </div>
                                {/* <div className="affilate-amount">
                                    <div className="primary-text">{item.type ? t(`enums.investType.${item.type}`) : ''}</div>
                                    <div className="secondary-text">{moment(item.createdAt).format('DD.MM.YYYY')}</div>
                                </div>
                                <div className="affilate-amount">
                                    <div className="primary-text">{formatCurrency(item.unpaidBonus)}</div>
                                    <div className="secondary-text">{moment(item.createdAt).format('DD.MM.YYYY')}</div>
                                </div> */}
                                <div className="affilate-amount"></div>
                            </div>
                        </>
                    ))}
                    <div className="mt-2">
                        <Paginator
                            pageIndex={pageIndex}
                            dataLength={pagination?.totalElements}
                            onPageChanged={(newPage: number) => {
                                loadInvestments(item.id, newPage);
                            }}
                            pageSize={10}
                        />
                    </div>
                    {investments.length === 0 && (
                        <div className="portfolio-empty">
                            <span>{t('affilate.empty-list')}</span>
                        </div>
                    )}
                </div>
            </Accordion.Collapse>
            {colorPicker && (
                <div className="color-picker">
                    <PhotoshopPicker
                        color={value}
                        onChange={(color: ColorResult) => setValue(color.hex)}
                        onAccept={() => {
                            updateColor(value, item.id);
                            setColorPicker(false);
                        }}
                        onCancel={() => {
                            setColorPicker(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | string;
};

function ContextAwareToggle({ eventKey, callback }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" onClick={decoratedOnClick}>
            {isCurrentEventKey ? 'Hide' : 'Show'}
        </button>
    );
}

export default SaleItem;
