import React from 'react';
import UserLogo from '../../../components/user-logo';
import { UserProfileType } from '../../../models/api/auth-models';
import { generateShortUserName } from '../../../utils/utils';

type PropsType = {
    eventKey: string;
    item: UserProfileType;
    handleBalance: (item: UserProfileType) => void;
    handleEdit: (item: UserProfileType) => void;
    handleWithdraw: (item: UserProfileType) => void;
};

const ClientItem: React.FC<PropsType> = ({ item, handleBalance, handleEdit, handleWithdraw }) => {
    return (
        <div className="affiliate-item">
            <div className="item-info-container">
                <div className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.name, item.surname)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{`${item.name} ${item.surname}`}</div>
                        <div className="secondary-text"></div>
                    </div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.email}</div>
                    <div className="secondary-text"></div>
                </div>
                <div className="affilate-amount">
                    <div className="primary-text">{item.phone}</div>
                    <div className="secondary-text"></div>
                </div>
                {/* <div></div> */}
                <div className="affilate-action">
                    <div className="button-group">
                        <button onClick={() => handleBalance(item)}>Add balance</button>
                        <button onClick={() => handleEdit(item)}>Edit</button>
                        <button onClick={() => handleWithdraw(item)}>Withdraw</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientItem;
