import React, { useEffect, useState, useRef } from 'react';
import adminService from '../../../service/adminService';
import BaseInput from '../../../components/common/base-input';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFormRules } from '../../../hooks/use-form-rules';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { InboxTypeEnum } from '../../../models/enums';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import BaseButton from '../../../components/common/base-button';
import { InboxRequestType } from '../../../models/api/dashboard-model';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw } from 'draft-js';
import LoadingIndicator from '../../../components/loading-indicator';
import { useToasts } from 'react-toast-notifications';
import { inboxColors } from '../../../utils/utils';

type InboxFormData = {
    subject: string;
};

type DraftUploadFileType = {
    file: File;
    localSrc: string;
};

function uploadImageCallBack(file: File) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://api.imgur.com/3/image');
        xhr.setRequestHeader('Authorization', 'Client-ID 1ca346e52e59359');
        const data = new FormData();
        data.append('image', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText);
            resolve(response);
        });
        xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
        });
    });
}
const InboxManager: React.FC = () => {
    const { t } = useTranslation();
    const { requiredTrimed } = useFormRules();
    const { addToast } = useToasts();

    const [type, setType] = useState<InboxTypeEnum>(InboxTypeEnum.INFORMATION);
    const [content, setContent] = useState<EditorState>();
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadImages, setUploadImages] = useState<DraftUploadFileType[]>([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        setValue,
    } = useForm();

    const handleClick = (formData: InboxFormData) => {
        // setLoading(true);
        const textContent = content ? draftToHtml(convertToRaw(content?.getCurrentContent())) : '';
        const newContent = textContent.replaceAll('<p>', '<p style="color: #fff">');
        const data: InboxRequestType = {
            content: newContent,
            type,
            subject: formData.subject,
        };
        adminService
            .sendInbox(data)
            .then(() => {
                setContent(undefined);
                setValue('subject', '');
                addToast(t('toast.messageSended'));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="inbox-container">
                <LoadingIndicator visible={loading} />
                <Row>
                    <Col>
                        <BaseInput {...register('subject', { ...requiredTrimed })} errors={errors} type="text" label={'Subject'} trigger={trigger} />
                    </Col>
                    <Col className="admin-inbox-header-col">
                        <div className="d-flex align-items-center">
                            <div
                                style={{ backgroundColor: inboxColors[type], height: '20px', width: '20px', borderRadius: '50%', marginRight: '8px' }}
                            ></div>
                            <Dropdown
                                className="search-typehead"
                                onSelect={(eventKey: any, event: any) => {
                                    setType(eventKey as InboxTypeEnum);
                                }}
                            >
                                <Dropdown.Toggle className="mobile-invest-btn">{type}</Dropdown.Toggle>

                                <Dropdown.Menu className="mobile-invest-dropdown">
                                    {Object.values(InboxTypeEnum).map((item, index) => (
                                        <Dropdown.Item eventKey={item} key={index}>
                                            {item}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Editor
                            editorState={content}
                            toolbar={{
                                options: [
                                    'inline',
                                    'blockType',
                                    'fontSize',
                                    'fontFamily',
                                    'list',
                                    'textAlign',

                                    'link',
                                    'embedded',
                                    'emoji',
                                    'image',
                                    'remove',
                                    'history',
                                ],
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                            }}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={setContent}
                        />
                    </Col>
                </Row>
                <div>
                    <BaseButton onClick={handleSubmit(handleClick)} className="auth-sign-in-btn btn-center">
                        {t('auth.send')}
                    </BaseButton>
                </div>
            </div>
        </>
    );
};

export default InboxManager;
