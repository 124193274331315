import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useUrlQuery } from '../../../hooks/use-url-query';
import { RouteModalPropsType } from '../../../models/models';
import Routes, { AuthRoutes } from '../../../routes/routes';
import LoadingIndicator from './../../../components/loading-indicator';
import authService from './../../../service/authService';
import { useTranslation } from 'react-i18next';

const RegistrationConfirm: React.FC<RouteModalPropsType> = ({ isOpened }) => {
    const urlQuery = useUrlQuery();
    const token = urlQuery.get('token');
    const history = useHistory();
    const { addToast } = useToasts();
    const { t } = useTranslation();

    useEffect(() => {
        if (token && isOpened) authService.registerConfirm(token).then(onSuccess, onError);
        function onSuccess() {
            setTimeout(() => {
                history.replace(AuthRoutes.LOGIN);
                addToast(t('toast.emailConfirmed'));
            }, 1000);
        }
        function onError() {
            setTimeout(() => {
                history.replace(Routes.ROOT);
                addToast(t('toast.linkNotValid'), { appearance: 'error' });
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpened, token, history, addToast]);

    return isOpened ? <LoadingIndicator visible /> : null;
};

export default RegistrationConfirm;
