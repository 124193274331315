import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SeifLogo } from '../../assets/images';
import { useFormRules } from '../../hooks/use-form-rules';
import { UserProfileType } from '../../models/api/auth-models';
import { ClientSimplified } from '../../models/api/dashboard-model';
import { RouteModalPropsType } from '../../models/models';
import BaseButton from '../common/base-button';
import BaseInput from '../common/base-input';

type PropsType = RouteModalPropsType & {
    clientsSimplified: ClientSimplified[];
    handleSend: (data: ConsoleEditFormType) => void;
    client?: UserProfileType;
};

export type ConsoleEditFormType = {
    parentId: string;
    email: string;
};

const ConsoleEditModal: React.FC<PropsType> = ({ isOpened, onClose, handleSend, clientsSimplified, client }) => {
    const { t } = useTranslation();
    const { requiredTrimed, formRules } = useFormRules();

    const {
        register,
        formState: { errors },
        trigger,
        getValues,
        reset,
    } = useForm();

    useEffect(() => {
        reset({ email: client?.email ?? '' });
    }, [client, reset]);

    const handleClick = () => {
        const data = getValues();
        handleSend(data as ConsoleEditFormType);
    };

    return (
        <>
            <Modal show={isOpened} size="sm" onHide={onClose} centered>
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <div className="auth-logo">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <div>
                            <BaseInput
                                {...register('email', { ...requiredTrimed, ...formRules.email })}
                                errors={errors}
                                type="text"
                                label={t('auth.email')}
                                trigger={trigger}
                            />

                            <div className="console-autocomplete">
                                <label htmlFor="exampleDataList" className="form-label">
                                    {t('auth.referal')}
                                </label>
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="exampleDataList"
                                    placeholder="Type to search..."
                                    {...register('parentId', { ...requiredTrimed })}
                                />
                                <datalist id="datalistOptions">
                                    {clientsSimplified.map((item, index) => (
                                        <option key={index} value={`${item.name} ${item.surname}`} />
                                    ))}
                                </datalist>
                            </div>

                            <BaseButton onClick={handleClick} className="auth-sign-in-btn btn-center mt-4">
                                {t('auth.send')}
                            </BaseButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ConsoleEditModal;
