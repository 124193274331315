import React from 'react';
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { SeifLogo, MobileLogo } from '../../../assets/images';
import { useUser } from '../../../context/user-context';
import { useLocalStorage } from '../../../hooks/use-local-storage';
import { RolesEnum } from '../../../models/enums';
import Routes, { AuthRoutes } from '../../../routes/routes';
import BaseButton from './../../../components/common/base-button';
import { TOKEN } from './../../../models/const';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faChartBar, faChartLine, faDoorOpen, faRocket, faSignInAlt, faSnowboarding } from '@fortawesome/free-solid-svg-icons';
import LandingRouteList from '../../../routes/landing-route-list';

type PropsType = {
    onLoginClick: () => void;
    handleLinkClick: (refName: string) => void;
};

const LandingHeader: React.FC<PropsType> = ({ onLoginClick, handleLinkClick }) => {
    const { user } = useUser();
    const history = useHistory();
    const { value: token } = useLocalStorage(TOKEN, '');
    const { t } = useTranslation();

    const redirectToDashboard = () => {
        if (user?.roles?.includes(RolesEnum.ADMIN)) return history.push(Routes.ADMIN);
        if (user?.roles?.includes(RolesEnum.EMPLOYEE)) return history.push(Routes.EMPLOYEE);
        if (user?.roles?.includes(RolesEnum.USER)) return history.push(Routes.USER);
        return history.push(AuthRoutes.LOGIN);
    };

    return (
        <>
            <Row className="landing-header-container">
                <Col className="mobile-padding-0">
                    <Navbar collapseOnSelect expand="lg" variant="dark">
                        <Container>
                            <Navbar.Brand>
                                <Link to={Routes.ROOT} role="button">
                                    <img src={SeifLogo} alt="" className="header-logo" />
                                    <img src={MobileLogo} alt="" className="mobile-logo" />
                                </Link>
                            </Navbar.Brand>
                        </Container>
                    </Navbar>
                </Col>
            </Row>
        </>
    );
};

export default LandingHeader;
