import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import App from './app';
import AppNotWork from './appNotWork';
import { CustomToast, CustomToastContainer } from './components/custom-toast';
import { UserProvider } from './context/user-context';
import { NotificationProvider } from './context/notification-context';
import './index.css';
import reportWebVitals from './reportWebVitals';
import TermsCondition from './components/TermsCondition';

const langWhitelist = ['sk', 'en', 'cz', 'pl', 'gr', 'xx'];

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        // lng: 'en',
        lng: localStorage.getItem('i18n.locale') || (langWhitelist.includes(navigator.language) && navigator.language) || 'en',
        debug: false,
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
        react: {
            useSuspense: false,
        },
        supportedLngs: langWhitelist,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ToastProvider
                autoDismiss
                placement="top-center"
                components={{
                    ToastContainer: CustomToastContainer,
                    Toast: CustomToast,
                }}
            >
                <UserProvider>
                    <NotificationProvider>
                        {process.env.REACT_APP_ONLINE ? (
                            <>
                                <App />
                                <TermsCondition />
                            </>
                        ) : (
                            <AppNotWork />
                        )}
                    </NotificationProvider>
                </UserProvider>
            </ToastProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
