import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveIcon, EstablishedIcon, StartupIcon } from '../../../assets/images';
import { AdminStatisticResposneType, StatisticPortfolioType } from '../../../models/api/dashboard-model';
import adminService from '../../../service/adminService';
import CircleChart from './../../../components/cirlce-chart';
import { formatCurrency } from './../../../utils/utils';

const Dashboard: React.FC = () => {
    const [statistics, setStatistics] = useState<AdminStatisticResposneType>();
    const { t } = useTranslation();

    useEffect(() => {
        adminService.getStatistics().then((response) => {
            setStatistics(response.data);
        });
    }, []);

    const getSummary = (item?: StatisticPortfolioType): number => {
        if (!item) return 0;
        return item?.ACTIVE + item?.ESTABLISHED + item?.STARTUP;
    };

    return (
        <>
            <div className="admin-dashboard-container">
                <div className="portfolio-grid-container admin-container">
                    <div className="startup">
                        <div className="item-header">
                            <div className="title">{t('dashboard.startup')}</div>
                            <img src={StartupIcon} alt="" />
                            {/* <FontAwesomeIcon icon={faSync} color="#9786b3" /> */}
                            <div></div>
                        </div>
                        <div className="item-chart">
                            <CircleChart percent={100} color="#997dc7" value={statistics?.totalCurrentAmount.STARTUP} />
                        </div>
                    </div>
                    <div className="established">
                        <div className="item-header">
                            <div className="title">{t('dashboard.established')}</div>
                            <img src={EstablishedIcon} alt="" />
                            {/* <FontAwesomeIcon icon={faSync} color="#ea9bc6" /> */}
                            <div></div>
                        </div>
                        <div className="item-chart">
                            <CircleChart percent={100} color="#ef73b7" value={statistics?.totalCurrentAmount.ESTABLISHED} />
                        </div>
                    </div>
                    <div className="active">
                        <div className="item-header">
                            <div className="title">{t('dashboard.active')}</div>
                            <img src={ActiveIcon} alt="" />
                            <div></div>
                            {/* <FontAwesomeIcon icon={faSync} color="#98cdec" /> */}
                        </div>
                        <div className="item-chart">
                            <CircleChart percent={100} color="#00d2ff" value={statistics?.totalCurrentAmount.ACTIVE} />
                        </div>
                    </div>
                </div>
                <div className="admin-statisics-grid-container">
                    <div className="statistic-item">
                        <div className="primaryLabel">{formatCurrency(getSummary(statistics?.totalCurrentAmount))}</div>
                        <div className="secondaryLabel">{t('dashboard.totalInvested')}</div>
                    </div>
                    <div className="statistic-item">
                        <div className="primaryLabel">{formatCurrency(getSummary(statistics?.totalDividents))}</div>
                        <div className="secondaryLabel">{t('dashboard.totalDividents')}</div>
                    </div>
                    <div className="statistic-item">
                        <div className="primaryLabel">{formatCurrency(getSummary(statistics?.totlaReinvested))}</div>
                        <div className="secondaryLabel">{t('dashboard.totlaReinvested')}</div>
                    </div>
                    <div className="statistic-item">
                        <div className="primaryLabel">{formatCurrency(statistics?.totalWithdrawnSum)}</div>
                        <div className="secondaryLabel">{t('dashboard.totalWithdrawed')}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
