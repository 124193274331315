import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router';
import RefferalSendModal from '../../../components/modals/refferal-send-modal';
import UserLogo from '../../../components/user-logo';
import { useUser } from '../../../context/user-context';
import { BaseFormType } from '../../../models/models';
import Routes, { AdminRoutes } from '../../../routes/routes';
import { generateShortUserName } from '../../../utils/utils';
import AdminRefferalLinkWidget from './../../../components/wigets/admin-refferal-link-widget';
import { ReferralFormType } from './refferals';

type PropsType = BaseFormType<ReferralFormType> & {
    usersCount: number;
    loading: boolean;
    onClose: () => void;
    onSendLink: () => void;
    handleSend: () => void;
};

const RefferalsView: React.FC<PropsType> = ({ onSendLink, handleSend, loading, onClose, register, errors, trigger, usersCount }) => {
    const { user } = useUser();
    const { t } = useTranslation();

    return (
        <div className="refferal-contaienr">
            <div className="refferal-tree-grid-container">
                <div className="refаeral-tree">
                    <div className="tree-first-column">
                        <div className="border-out">
                            <div className="border-in">
                                <UserLogo name={generateShortUserName(user?.name, user?.surname)} />
                            </div>
                        </div>
                        <div className="user-name">{`${user?.name} ${user?.surname}`}</div>
                    </div>
                    <div className="tree-second-column">
                        <div className="border-out">
                            <div className="border-in">
                                <div className="position-absolute">{t('common.employee')}</div>
                            </div>
                        </div>
                        <div className="border-out">
                            <div className="border-in">
                                <div className="position-absolute">{t('common.employee')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="tree-third-column">
                        <div className="tree-third-wrapper">
                            <div className="border-out">
                                <div className="border-in">
                                    <div className="position-absolute">{t('common.client')}</div>
                                </div>
                            </div>
                            <div className="circle-wrapper">
                                <div className="small-circle"></div>
                                <div className="small-circle"></div>
                                <div className="small-circle"></div>
                            </div>
                        </div>

                        <div className="tree-third-wrapper">
                            <div className="border-out">
                                <div className="border-in">
                                    <div className="position-absolute">{t('common.client')}</div>
                                </div>
                            </div>
                            <div className="circle-wrapper">
                                <div className="small-circle"></div>
                                <div className="small-circle"></div>
                                <div className="small-circle"></div>
                            </div>
                        </div>

                        <div className="tree-third-wrapper">
                            <div className="border-out">
                                <div className="border-in">
                                    <div className="position-absolute">{t('common.client')}</div>
                                </div>
                            </div>
                            <div className="circle-wrapper">
                                <div className="small-circle"></div>
                                <div className="small-circle"></div>
                                <div className="small-circle"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="refferal-info">
                    <p>
                        <Trans i18nKey="refferals.infoText" />
                    </p>
                </div>
            </div>
            <div className="refferal-grid-container">
                <AdminRefferalLinkWidget onSend={onSendLink} />
                <div className="users-widget">
                    <div className="users-count">{usersCount}</div>
                    <div className="users-title">{t('refferals.registeredPeople')}</div>
                </div>
            </div>

            <Route
                path={`${AdminRoutes.REFFERALS}${Routes.SEND_REFERAL}`}
                exact
                // eslint-disable-next-line react/no-children-prop
                children={({ match }) => (
                    <RefferalSendModal
                        isOpened={Boolean(match)}
                        onClose={onClose}
                        loading={loading}
                        handleSend={handleSend}
                        register={register}
                        errors={errors}
                        trigger={trigger}
                    />
                )}
            />
            <Redirect to={AdminRoutes.REFFERALS} />
        </div>
    );
};

export default RefferalsView;
