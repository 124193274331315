import React from 'react';
import { useTranslation } from 'react-i18next';
import StripeCheckout, { Token } from 'react-stripe-checkout';
import { useToasts } from 'react-toast-notifications';
import clientService from '../../../../service/clientService';
import { Button } from 'react-bootstrap';

type PropsType = {
    amount: number;
    update: () => void;
};

const CheckoutButton: React.FC<PropsType> = ({ amount, update }) => {
    const publishableKey = process.env.REACT_APP_STIPE_KEY ?? '';
    const stripePrice = amount * 100;

    const { addToast } = useToasts();
    const { t } = useTranslation();

    const onToken = (token: Token) => {
        clientService.paymentRequest(token.id, amount).then(
            () => {
                update();
                addToast(t('toast.paymentSuccess'));
            },
            () => {
                addToast(t('toast.paymentFailed'), { appearance: 'error' });
            },
        );
    };

    return (
        <StripeCheckout
            amount={stripePrice}
            label={t('invest.payLabel')}
            name="Seif"
            // billingAddress
            // shippingAddress
            description={t('invest.payDescription', { amount })}
            panelLabel={t('invest.payLabel')}
            token={onToken}
            stripeKey={publishableKey}
            currency="EUR"
        >
            <Button className="sign-in-btn">{t('navlinks.invest')}</Button>
        </StripeCheckout>
    );
};

export default CheckoutButton;
