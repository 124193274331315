import React from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BalanceIcon, InfoIcon, TradeInProgress, TradeWaiting, WidgetBg } from '../../../../assets/images';
import { formatCurrency } from './../../../../utils/utils';

type PropsType = {
    total: number;
    firstWeekTradingInvestmentsAmount?: number;
    pendingInvestmentsAmount?: number;
};

const DashboradTotal: React.FC<PropsType> = ({ total, firstWeekTradingInvestmentsAmount, pendingInvestmentsAmount }) => {
    const { t } = useTranslation();

    const tradingDisclaimer = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('dashboard.tradingDisclaimer')}
        </Tooltip>
    );
    return (
        <div className="total total-container">
            <div className="total-price-wrapper">
                <div className="total-item">
                    <div className="total-primary-label justify-content-between d-flex">
                        <div className="flex-4">{formatCurrency(total)}</div>
                        {/* <TriangleFill size=".4em" /> */}
                        <img src={BalanceIcon} className="img-total-balance" />
                    </div>
                    <div className="total-secondary-label">{t('dashboard.total')}</div>
                </div>
            </div>
            <div className="trade-bg">
                <Row className="total-item m-3">
                    <Col className="d-flex align-items-center" xs lg="2">
                        <img src={TradeInProgress} className="img-total-balance" />
                    </Col>
                    <Col md="auto">
                        <div className="trade-in-progress-amount">{formatCurrency(firstWeekTradingInvestmentsAmount)}</div>
                        <div className="trade-in-progress">{t('dashboard.firstWeekTradingInvestmentsAmount')}</div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={tradingDisclaimer}>
                            <Button className="bg-transparent border-0 progress-disclaimer p-0">
                                <img src={InfoIcon} className="info-icon-size" alt="" />
                            </Button>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="total-item m-3">
                    <Col className="d-flex align-items-center" xs lg="2">
                        <img src={TradeWaiting} alt="" className="img-total-balance" />
                    </Col>
                    <Col md="auto">
                        <div className="waiting-trading-amount">{formatCurrency(pendingInvestmentsAmount)}</div>
                        <div className="waiting-trade">{t('dashboard.pendingInvestmentsAmount')}</div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default DashboradTotal;
