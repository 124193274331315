import { sk } from 'date-fns/locale';
import moment from 'moment';
import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import './react-datepicker.scss';
registerLocale('sk', sk);

type DatePicker = {
    minDate?: Date;
    maxDate?: Date;
    getValues?: any;
    setValue?: any;
    children: JSX.Element;
    name: string;
};

export function formatDate(date: string | undefined): string | undefined {
    return date ? moment(date, ['DD-MM-YYYY', 'DD.MM.YYYY', 'D-M-YYYY', 'D.M.YYYY'], true).format('YYYY-MM-DDT00:00:00') : undefined;
}

const DatePicker: React.FC<DatePicker> = ({ minDate, maxDate, setValue, name, children }) => {
    const { i18n } = useTranslation();
    const refCalendar = useRef<ReactDatePicker>(null);

    const lang = i18n.language;

    const [date, setDate] = useState<Date>();

    const [isTouched, setIsTouched] = useState(false);

    useEffect(() => {
        setValue(name, '');
    }, [setValue, name]);

    return (
        <>
            <ReactDatePicker
                ref={refCalendar}
                locale={lang === 'en' ? 'en' : 'sk'}
                popperClassName={''}
                minDate={minDate}
                maxDate={maxDate}
                selected={date}
                showYearDropdown
                peekNextMonth
                showMonthDropdown
                yearDropdownItemNumber={80}
                scrollableYearDropdown
                customInput={children}
                renderDayContents={(day) => {
                    return <span className="my_class">{day}</span>;
                }}
                onChange={(date) => {
                    if (isTouched === false) {
                        setIsTouched(true);
                    }
                    if (date !== null && date instanceof Date) {
                        date.setHours(date.getHours() + 2);
                        setDate(date);
                        setValue(name, date);
                    }
                    if (!date) {
                        setValue(name, '');
                        setDate(undefined);
                    }
                }}
            />
        </>
    );
};
export default DatePicker;
