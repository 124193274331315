import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { FieldErrors, UseFormTrigger } from 'react-hook-form';
import { accessNestedProperty } from '../../utils/utils';

type PropsType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    name: string;
    label?: string | JSX.Element;
    placeholder?: string;
    className?: string;
    errors?: FieldErrors;
    trigger: UseFormTrigger<any>;
    paswordVisible: boolean;
    handlePasswordVisibility: () => void;
};

const PasswordInput = forwardRef(
    ({ label, placeholder = ' ', className = '', errors, trigger, paswordVisible, handlePasswordVisibility, ...params }: PropsType, ref: any) => {
        const errorProperty = params.name == null ? undefined : accessNestedProperty({ ...errors }, params.name.split('.'));
        const isValid = errors == null || errorProperty == null;

        const [isTouched, setIsTouched] = useState<boolean>(false);
        const manualErrorCheck = useCallback(() => {
            trigger && trigger(params.name);
        }, [params.name, trigger]);

        useEffect(() => {
            !isValid && manualErrorCheck();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [manualErrorCheck]);

        return (
            <>
                <div className={`form-floating input-group  mt-2 ${className}`}>
                    <input
                        id={`id-${params.name}`}
                        ref={ref}
                        {...params}
                        type={paswordVisible ? 'text' : 'password'}
                        placeholder={placeholder}
                        autoComplete="OFF"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            params.onChange && params.onChange(e);
                            manualErrorCheck();
                            if (isTouched === false) {
                                setIsTouched(true);
                            }
                        }}
                        className={classNames(className || '', 'form-control', {
                            'is-invalid': !isValid,
                            // 'is-valid': isValid && isTouched,
                        })}
                    />
                    <span className="input-group-text" id="basic-addon2" onClick={handlePasswordVisibility}>
                        <FontAwesomeIcon icon={paswordVisible ? faEyeSlash : faEye} className="p-icon" />
                    </span>

                    <label htmlFor={`id-${params.name}`} className="form-label mb-2">
                        {label}
                    </label>
                </div>
            </>
        );
    },
);

export default PasswordInput;
