import React, { useContext } from 'react';
import { Accordion, AccordionContext, Col, Row, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserLogo from '../../../../components/user-logo';
import { AffiliateType } from '../../../../models/api/dashboard-model';
import { formatCurrency, generateShortUserName } from '../../../../utils/utils';

const lineColor = [
    '#aa7bf6',
    '#ffffff',
    '#1EADF0',
    '#0AFB60',
    '#FFE031',
    '#F04579',
    '#E5F230',
    '#0100EC',
    '#FB36F4',
    '#00FFED',
    '#9D00C6',
    '#F237EF',
    '#FC5252',
];

type PropsType = {
    eventKey: string;
    item: AffiliateType;
    loadChild: (id: number, line: number) => void;
};

const AffiliateItem: React.FC<PropsType> = ({ eventKey, item, loadChild }) => {
    const { t } = useTranslation();

    return (
        <ContextAwareToggle eventKey={eventKey} callback={() => item.members > 0 && loadChild(item.id, item.line)}>
            <Row className="item-info-container-client">
                <div className="affilate-parent-color" style={{ backgroundColor: lineColor[item.line] || '#cecece' }}></div>

                <Col className="user-profile">
                    <div className="user-img">
                        <UserLogo name={generateShortUserName(item.fullName)} small />
                    </div>
                    <div className="user-info">
                        <div className="primary-text">{item.fullName}</div>
                        <div className="secondary-text">{item.line} line</div>
                    </div>
                </Col>
                <Col className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.bonuses)}</div>
                    <div className="secondary-text">Paid to you</div>
                </Col>
                <Col className="affilate-amount">
                    <div className="primary-text">{item.members}</div>
                    <div className="secondary-text">members</div>
                </Col>
                <Col className="affilate-amount">
                    <div className="primary-text">{formatCurrency(item.invested)}</div>
                    <div className="secondary-text">Total invested</div>
                </Col>
            </Row>
        </ContextAwareToggle>
    );
};

type ContextAwareToggleProps = {
    eventKey: string;
    callback?: (eventKey: string) => void;
    children?: JSX.Element | JSX.Element[] | string;
};

function ContextAwareToggle({ eventKey, callback, children }: ContextAwareToggleProps) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div onClick={decoratedOnClick} className="affiliate-toggle" id={eventKey}>
            {children}
        </div>
    );
}

export default AffiliateItem;
