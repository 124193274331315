import { useTranslation } from 'react-i18next';
import { formatPhoneNumber, validateIban } from '../utils/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormRules = () => {
    const { t } = useTranslation();

    // eslint-disable-next-line no-control-regex
    const emailRegex =
        /^(?:[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    // eslint-disable-next-line
    const phoneValidCharacters = /^[0-9\+\s\/.,\-()]*$/;
    const onlyNumbersRegex = /^[0-9]*$/;
    const phoneNumberInternationalSKRegex = /(^$)|(^([+]|00)421[1-9][0-9]{8}$)/;
    const phoneNumberInternationalRegex = /(^$)|(^[0-9]{9,15}$)/;
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.]\d{4}$/;
    const ibanRegex =
        /^([a-zA-Z]{2}[0-9]{2}(?:[ ]?([0-9]|[A-Z]){4}){1}([ ]?[0-9]{4}){2}(([ ]?[0-9]{4}){1,3})?((([ ]?[0-9]{4})?([ ]?[0-9]{1,2}))|(([ ]?[0-9]{1,4})|([ ]?[0-9]{1,2})))[ ]?)$/;
    const numberWithSpecialCharactersRegex = /^[0-9a\-/]*$/;

    const numericRules = ({ min, max }: { min?: number; max?: number }) => {
        return {
            validate: {
                number: (value: string) => {
                    const m = t('common.input-rules.only-numbers', { max: max });
                    return onlyNumbersRegex.test(value) || m;
                },
                lo: (value: number | string) => {
                    const m = t('common.input-rules.min', { min: min });
                    return min == null || value >= min || m;
                },
                hi: (value: number | string) => {
                    const m = t('common.input-rules.max', { max: max });
                    return max == null || value <= max || m;
                },
            },
        };
    };

    const formRules = {
        required: {
            required: { value: true, message: t('common.input-rules.required') },
        },
        length: (length: number) => {
            return {
                validate: {
                    length: (value: string) => {
                        if (value && value.length !== length) {
                            return t('common.input-rules.value-length', { count: length });
                        }
                    },
                },
            };
        },
        minLengthX: (x: number) => {
            return {
                minLength: {
                    value: x,
                    message: t('common.input-rules.minLength', { minLength: x }),
                },
            };
        },
        maxLengthX: (x: number) => {
            return {
                maxLength: {
                    value: x,
                    message: t('common.input-rules.maxLength', { maxLength: x }),
                },
            };
        },
        min: (x: number) => {
            return {
                min: { value: x, message: t('common.input-rules.min', { min: x }) },
            };
        },
        max: (x: number) => {
            return {
                max: { value: x, message: t('common.input-rules.max', { max: x }) },
            };
        },
        number: {
            validate: {
                numberValidation: (value: string) => {
                    const num = Number(value);
                    if (value.indexOf('e') >= 0 || isNaN(num) || !isFinite(num)) {
                        return t('common.input-rules.requiredNumber');
                    }
                },
            },
        },
        //Issue with following patterns is that only one will be applied. If you need multiple-pattern combination, create new complex pattern or separate rules object e.g. passwordRules
        email: {
            pattern: { value: emailRegex, message: t('common.input-rules.email') },
        },
        iban: {
            pattern: { value: ibanRegex, message: t('common.input-rules.iban') },
            validate: {
                checksum: (value: string) => {
                    if (value && value.trim().length > 0 && !validateIban(value.trim())) {
                        return t('common.input-rules.iban');
                    }
                },
            },
        },
        url: { pattern: { value: urlRegex, message: t('common.input-rules.url') } },
        validDate: {
            pattern: { value: dateRegex, message: t('common.input-rules.date') },
        },
        dateCharacters: { pattern: { value: numberWithSpecialCharactersRegex, message: t('common.input-rules.invalid-name') } },
    };

    const required = t('common.input-rules.required');
    const requiredTrimed = {
        validate: {
            requiredTrimedNotEmpty: (value: string) => (value && String(value).trim() !== '') || required,
        },
    };

    const equalsMessage = (other: string | number) => t('common.input-rules.value-must-equal', { other: other });

    const equals = (other: string | number, message: string) => ({
        validate: {
            str: (value: string | number) => {
                return value?.toString() === other?.toString() || equalsMessage(message);
            },
        },
    });

    const phoneNumber = t('common.input-rules.phoneNumber');
    const phoneNumberValidCharacters = t('common.input-rules.phoneValidCharacters');
    const phoneRules = {
        validate: {
            validateCharacters: (value: string) => phoneValidCharacters.test(value) || phoneNumberValidCharacters,
            validateNumberInternational: (value: string) => {
                const formatedPhoneNumber = formatPhoneNumber(value);
                return (
                    (formatedPhoneNumber.startsWith('+421')
                        ? phoneNumberInternationalSKRegex.test(formatedPhoneNumber)
                        : phoneNumberInternationalRegex.test(formatedPhoneNumber)) || phoneNumber
                );
            },
        },
    };

    const requiredTrimedFunction = (requiredValue = false) => {
        return {
            validate: {
                requiredTrimedNotEmpty: (value: string) => {
                    if (!requiredValue) {
                        return true;
                    } else {
                        return (value && String(value).trim() !== '') || required;
                    }
                },
            },
        };
    };

    const mergeValidations = (validations: { [key: string]: (value: unknown) => string | boolean }) => {
        const validator = {
            validate: {
                ...validations,
            },
        };
        return validator;
    };

    return {
        formRules,
        requiredTrimed,
        phoneRules,
        requiredTrimedFunction,
        mergeValidations,
        equals,
        numericRules,
    };
};
