import React from 'react';
import { ProfileType } from '../../../models/api/dashboard-model';
import ProfileAccountInfo from './components/profile-account-info';
import ProfileAddress from './components/profile-address';
import ProfileId from './components/profile-id';
import ProfileUserInfo from './components/profile-user-info';

type PropsType = {
    data?: ProfileType;
    updateData: () => void;
};

const ProfileView: React.FC<PropsType> = ({ data, updateData }) => {
    return (
        <div className="profile-container">
            <ProfileUserInfo />
            {/* <ProfileId /> */}
            {/* <ProfileAddress /> */}
            <ProfileAccountInfo wallet={data?.cryptoWallet} iban={data?.iban} updateData={updateData} />
        </div>
    );
};

export default ProfileView;
