import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router';
import { RolesEnum } from '../../models/enums';
import { AdminRoutes, EmployeeRoutes } from '../../routes/routes';
import BaseHeader from './../../components/header/base-header';
import BaseSidebar from './../../components/sidebar/base-sidebar';
import RoleRoute from './../../routes/guard/role-route';
import Clients from './clients/clients';
import Dashboard from './dashboard/dashboard';
import Refferals from './refferals/refferals';
import EmployeeAffiliate from './affiliate/affiliate';
import ClientDashboard from '../admin/client-dashboard/ClientDashboard';
import Employees from '../admin/employees/employees';
import ClientStatistic from '../admin/statistic/client-statistic';
import ReferralStatistic from '../admin/statistic/referal-statistic';
import SaleStatistic from '../admin/statistic/sale-statistic';
import { useRoles } from '../../hooks/use-roles';
import { Container } from 'react-bootstrap';

const Employee: React.FC = () => {
    const { t } = useTranslation();
    const { hasRole } = useRoles();

    const employeeLinks = useMemo(() => {
        return [
            {
                to: EmployeeRoutes.DASHBOARD,
                icon: '',
                title: t('navlinks.dashboard'),
            },
            {
                to: EmployeeRoutes.CLIENTS,
                icon: '',
                title: t('navlinks.clients'),
            },
            {
                to: EmployeeRoutes.REFFERALS,
                icon: '',
                title: t('navlinks.refferals'),
            },
            {
                to: EmployeeRoutes.AFFILIATE,
                icon: '',
                title: t('navlinks.affiliate'),
            },
            ...(hasRole(RolesEnum.SUPERVISOR)
                ? [
                      {
                          to: EmployeeRoutes.CLIENT_DASHBOARD,
                          icon: '',
                          title: t('navlinks.clientDashboard'),
                      },
                      {
                          to: EmployeeRoutes.CLIENT_STATISTIC,
                          icon: '',
                          title: t('navlinks.clientStatistic'),
                      },
                      {
                          to: EmployeeRoutes.SALE_STATISTIC,
                          icon: '',
                          title: t('navlinks.saleStatistic'),
                      },
                      {
                          to: EmployeeRoutes.REFERAL_STATISTIC,
                          icon: '',
                          title: t('navlinks.referralStatistic'),
                      },
                      {
                          to: EmployeeRoutes.EMPLOYEES,
                          icon: '',
                          title: t('navlinks.employees'),
                      },
                  ]
                : []),
        ];
    }, [t, hasRole]);

    return (
        <>
            <div className="container-fluid app-container">
                <BaseSidebar links={employeeLinks} />
                <div>
                    <BaseHeader />
                    <div className="page-content m-4">
                        <Switch>
                            <RoleRoute exact path={EmployeeRoutes.DASHBOARD} component={Dashboard} role={RolesEnum.EMPLOYEE} />
                            <RoleRoute exact path={EmployeeRoutes.CLIENTS} component={Clients} role={RolesEnum.EMPLOYEE} />
                            <RoleRoute exact path={EmployeeRoutes.AFFILIATE} component={EmployeeAffiliate} role={RolesEnum.EMPLOYEE} />
                            <RoleRoute path={EmployeeRoutes.REFFERALS} component={Refferals} role={RolesEnum.EMPLOYEE} />
                            <RoleRoute exact path={EmployeeRoutes.CLIENT_DASHBOARD} component={ClientDashboard} role={RolesEnum.SUPERVISOR} />
                            <RoleRoute exact path={EmployeeRoutes.CLIENT_STATISTIC} component={ClientStatistic} role={RolesEnum.SUPERVISOR} />
                            <RoleRoute exact path={EmployeeRoutes.SALE_STATISTIC} component={SaleStatistic} role={RolesEnum.SUPERVISOR} />
                            <RoleRoute exact path={EmployeeRoutes.REFERAL_STATISTIC} component={ReferralStatistic} role={RolesEnum.SUPERVISOR} />
                            <RoleRoute exact path={EmployeeRoutes.EMPLOYEES} component={Employees} role={RolesEnum.SUPERVISOR} />
                        </Switch>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Employee;
