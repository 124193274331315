import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BankLogo, SeifLogo } from '../../assets/images';
import { useFormRules } from '../../hooks/use-form-rules';
import { UserProfileType } from '../../models/api/auth-models';
import { RouteModalPropsType } from '../../models/models';
import BaseButton from '../common/base-button';
import BaseInput from '../common/base-input';
import LoadingIndicator from '../loading-indicator';

type PropsType = RouteModalPropsType & {
    handleSend: (data: ConsoleWithdrawFormType) => void;
    client?: UserProfileType;
};

export type ConsoleWithdrawFormType = {
    iban: string;
    activeAmount: number;
};

const ConsoleWithdrawModal: React.FC<PropsType> = ({ isOpened, onClose, handleSend, client }) => {
    const { t } = useTranslation();
    const { formRules } = useFormRules();
    const [canReplace, setCanReplace] = useState<boolean>(true);

    const {
        register,
        formState: { errors },
        trigger,
        setValue,
        watch,
        handleSubmit,
    } = useForm();

    const iban: string = (watch && watch(`iban`)) ?? '';

    useEffect(() => {
        if (iban) {
            const preview = iban
                .toUpperCase()
                .replace(/\W/gi, '')
                .replace(/(.{4})/g, '$1 ');

            canReplace && setValue && setValue(`iban`, preview);
        }
    }, [iban, setValue, canReplace]);

    return (
        <>
            {/* <LoadingIndicator visible={loading} /> */}
            <Modal show={isOpened} size="sm" onHide={onClose} centered>
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <div className="auth-logo">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <div>
                            <BaseInput
                                {...register('activeAmount', { ...formRules.required, ...formRules.number })}
                                errors={errors}
                                type="text"
                                label={'Active trading amount'}
                                trigger={trigger}
                            />
                            <BaseInput
                                {...register('iban', { ...formRules.required, ...formRules.iban })}
                                errors={errors}
                                icon={BankLogo}
                                type="text"
                                label={t('withdrawal.iban')}
                                trigger={trigger}
                                onKeyDown={(e) => {
                                    if (e.nativeEvent.key === 'Backspace' || e.nativeEvent.key === 'Delete') {
                                        setCanReplace(false);
                                    } else {
                                        setCanReplace(true);
                                    }
                                }}
                            />
                            {/* <BaseInput
                                {...register('card', {})}
                                errors={errors}
                                icon={UsdtLogo}
                                type="text"
                                label={t('withdrawal.usdt')}
                                trigger={trigger}
                            /> */}

                            <BaseButton onClick={handleSubmit(handleSend)} className="auth-sign-in-btn btn-center mt-4">
                                Withdraw
                            </BaseButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ConsoleWithdrawModal;
