import { AxiosResponse } from 'axios';
import {
    ClientInvestmentResponseType,
    ClientNoteRequestType,
    ClientNoteResponseType,
    ClientPortfolioType,
    ClientSimplifiedResponse,
    ClientStatisticPortfolioType,
    EmpClientsResponseType,
    EmployeeStatisticType,
    InvestmentResponseType,
    PagebleReqeustType,
    ProfitsResponseType,
    PrortfolioResponseType,
    UserHistoryResponseType,
    WithdrawalRequestsResponseType,
} from '../models/api/dashboard-model';
import { HistoryEnum } from '../models/enums';
import axiosInstance from './axiosConfig';

const employeeService = {
    inviteClient: (email: string): Promise<AxiosResponse<unknown>> => {
        return axiosInstance.post('/employee/invite-client', { to: email });
    },
    getClients: (name: string, email: string, pageable: PagebleReqeustType): Promise<AxiosResponse<EmpClientsResponseType>> => {
        return axiosInstance.get(`/employee/get-clients`, { params: { email, name, ...pageable } });
    },
    getStatistics: (): Promise<AxiosResponse<EmployeeStatisticType>> => {
        return axiosInstance.get('/employee/get-statistics');
    },
    getProfits: (username: string): Promise<AxiosResponse<ProfitsResponseType>> => {
        return axiosInstance.get('/client-dashboard/get-profits', { params: { username } });
    },
    getHistory: (username: string, term: HistoryEnum): Promise<AxiosResponse<UserHistoryResponseType>> => {
        return axiosInstance.get('/client-dashboard/get-history', { params: { term, username } });
    },
    getInvestment: (username: string): Promise<AxiosResponse<InvestmentResponseType>> => {
        return axiosInstance.get('/client-dashboard/get-investments', { params: { username } });
    },
    getWithdrawal: (username: string): Promise<AxiosResponse<WithdrawalRequestsResponseType>> => {
        return axiosInstance.get('/client-dashboard/get-approved-payout-requests', { params: { username, size: 100 } });
    },
    getClientPortfolios: (id: number): Promise<AxiosResponse<ClientStatisticPortfolioType[]>> => {
        return axiosInstance.get('/employee/get-clients-portfolios', { params: { paymentId: id } });
    },
    getClientsSimplified: (filter: string): Promise<AxiosResponse<ClientSimplifiedResponse>> => {
        return axiosInstance.get('/employee/get-clients-simplified', { params: { filter } });
    },
    editNote: (data: ClientNoteRequestType): Promise<AxiosResponse<ClientNoteResponseType>> => {
        return axiosInstance.post(`/employee/edit-note`, data);
    },
    getClientInvestments: (clientId: number, pageable: PagebleReqeustType): Promise<AxiosResponse<ClientInvestmentResponseType>> => {
        return axiosInstance.get('/employee/get-clients-investments', { params: { clientId, ...pageable } });
    },
};

export default employeeService;
