import React from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import BaseButton from '../../../components/common/base-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { LangEnum } from '../../../models/enums';
import homeBg from '../../../assets/video/home-bg.mp4';

type PropsType = {
    onLoginClick: () => void;
};

const SectionHome: React.FC<PropsType> = ({ onLoginClick }) => {
    const { i18n } = useTranslation();

    const changeLanguage = (lang: LangEnum) => {
        localStorage.setItem('i18n.locale', lang);
        i18n.changeLanguage(lang);
    };

    return (
        <div className="home-container">
            <Row className="m-0 w-100">
                <Col className="landing-grid-wrapper">
                    <div className="section-container">
                        <video width="320" height="240" autoPlay loop muted id="home-bg">
                            <source src={homeBg} type="video/mp4" />
                        </video>
                    </div>
                </Col>
            </Row>
            <div className="lang-dropdown">
                <Row className="m-0">
                    <Col>
                        <div className="my-5 ">
                            <div className="language-btn">
                                <Dropdown drop={'up'} className="search-typehead">
                                    <Dropdown.Toggle id="dropdown-basic" className="btn dropdown-toggle language-bg">
                                        <Trans i18nKey={`landing.${i18n.language}`} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="mb-1 w-100">
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.EN)}>
                                            <Trans i18nKey="landing.en" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.SK)}>
                                            <Trans i18nKey="landing.sk" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.CZ)}>
                                            <Trans i18nKey="landing.cz" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.PL)}>
                                            <Trans i18nKey="landing.pl" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage(LangEnum.GR)}>
                                            <Trans i18nKey="landing.gr" />
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SectionHome;
