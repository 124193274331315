import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import LoadingIndicator from '../../../components/loading-indicator';
import ConsoleWithdrawModal, { ConsoleWithdrawFormType } from '../../../components/modals/console-withdraw-modal';
import { UserProfileType } from '../../../models/api/auth-models';
import { ClientSimplified, ClientsPaginateResponseType } from '../../../models/api/dashboard-model';
import ConsoleBalanceModal, { ConsoleBalanceFormType } from './../../../components/modals/console-balance-modal';
import ConsoleEditModal, { ConsoleEditFormType } from './../../../components/modals/console-edit-modal';
import Paginator from './../../../components/paginator';
import { useDebounce } from './../../../hooks/use-debounce';
import adminService from './../../../service/adminService';
import ClientItem from './client-item';

const AdminConsole: React.FC = () => {
    const [clients, setClients] = useState<UserProfileType[]>();
    const [clientsSimplified, setClientclientsSimplified] = useState<ClientSimplified[]>([]);
    const [balanceModal, setBalanceModal] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [withdrawModal, setWithdrawModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentClient, setCurrentClient] = useState<UserProfileType>();
    const [searchName, setSearchName] = useState<string>('');
    const [searchRefferal, setSearchRefferal] = useState<string>('');
    const [searchEmail, setSearchEmail] = useState<string>('');

    const [pagination, setPagination] = useState<ClientsPaginateResponseType>();
    const [pageIndex, setPageIndex] = useState<number>(0);

    const debounceNameSearch = useDebounce<string>(searchName.trim(), 600);
    const debounceRefferalSearch = useDebounce<string>(searchRefferal.trim(), 600);
    const debounceEmailSearch = useDebounce<string>(searchEmail.trim(), 600);

    const { addToast } = useToasts();
    const { t } = useTranslation();

    const loadUsers = async (pageIndex: number, refferal?: string, name?: string, email?: string) => {
        const result = await adminService.getUsers(pageIndex, refferal, name, email);
        setLoading(false);

        setClients(result.data.users.content);
        setPagination(result.data);
    };

    useEffect(() => {
        setLoading(true);
        loadUsers(0);
        adminService.getUsersSimplified().then((res) => {
            setClientclientsSimplified(res.data.users);
        });
    }, []);

    useEffect(() => {
        loadUsers(pageIndex, debounceRefferalSearch, debounceNameSearch, debounceEmailSearch);
    }, [pageIndex, debounceRefferalSearch, debounceNameSearch, debounceEmailSearch]);

    const updateBalance = (data: ConsoleBalanceFormType) => {
        if (!currentClient) return;
        setLoading(true);
        try {
            adminService.emulatePayment({ ...data, username: currentClient.username });
            setBalanceModal(false);
            addToast(t('toast.paymentSuccess'));
        } catch {
            addToast(t('toast.paymentFailed', { appearance: 'error' }));
        } finally {
            setLoading(false);
        }
    };
    const updateUser = async (data: ConsoleEditFormType) => {
        if (!currentClient) return;
        setLoading(true);
        try {
            const parentId = clientsSimplified.find((item) => `${item.name} ${item.surname}` == data.parentId)?.id ?? null;
            // if (!parentId) {
            //     return addToast(t('toast.editFailed'), { appearance: 'error' });
            // }
            await adminService.updateUser({ email: data.email, parentId, id: currentClient.id });
            setEditModal(false);
            addToast(t('toast.editSuccess'));
        } catch {
            addToast(t('toast.editFailed'), { appearance: 'error' });
        } finally {
            setLoading(false);
            loadUsers(pageIndex);
        }
    };

    const withdraw = async (data: ConsoleWithdrawFormType) => {
        if (!currentClient) return;
        setLoading(true);
        try {
            await adminService.forcePayout({ ...data, userId: currentClient.id });
            setWithdrawModal(false);
            addToast(t('toast.forcePayoutSuccess'));
        } catch {
            addToast(t('toast.forcePayoutFailed'), { appearance: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="console-page-container">
            <div className="console-input-wrapper">
                <div className="console-autocomplete">
                    <label htmlFor="exampleDataList" className="form-label">
                        {t('auth.referal')}
                    </label>
                    <input
                        type="text"
                        id="search-refferal"
                        value={searchRefferal}
                        onChange={(e) => setSearchRefferal(e.currentTarget.value)}
                        placeholder=""
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                </div>
                <div className="console-autocomplete">
                    <label htmlFor="exampleDataList" className="form-label">
                        {t('auth.name')}
                    </label>
                    <input
                        type="text"
                        id="search-name"
                        value={searchName}
                        onChange={(e) => setSearchName(e.currentTarget.value)}
                        placeholder=""
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                </div>
                <div className="console-autocomplete">
                    <label htmlFor="exampleDataList" className="form-label">
                        {t('auth.email')}
                    </label>
                    <input
                        type="text"
                        id="search-gmail"
                        placeholder=""
                        value={searchEmail}
                        onChange={(e) => setSearchEmail(e.currentTarget.value)}
                        autoComplete="OFF"
                        className={'form-control'}
                    />
                </div>
            </div>
            {clients?.map((i, index) => (
                <ClientItem
                    key={index}
                    eventKey={`key${index}`}
                    item={i}
                    handleBalance={(client: UserProfileType) => {
                        setCurrentClient(client);
                        setBalanceModal(true);
                    }}
                    handleEdit={(client: UserProfileType) => {
                        setEditModal(true);
                        setCurrentClient(client);
                    }}
                    handleWithdraw={(client: UserProfileType) => {
                        setWithdrawModal(true);
                        setCurrentClient(client);
                    }}
                />
            ))}
            <Paginator
                pageIndex={pageIndex}
                dataLength={pagination?.users.totalElements}
                onPageChanged={(newPage: number) => {
                    setPageIndex(newPage);
                }}
                pageSize={20}
            />
            <LoadingIndicator visible={loading} />
            <ConsoleBalanceModal client={currentClient} isOpened={balanceModal} onClose={() => setBalanceModal(false)} handleSend={updateBalance} />
            <ConsoleEditModal
                client={currentClient}
                isOpened={editModal}
                onClose={() => setEditModal(false)}
                handleSend={updateUser}
                clientsSimplified={clientsSimplified}
            />
            <ConsoleWithdrawModal client={currentClient} isOpened={withdrawModal} onClose={() => setWithdrawModal(false)} handleSend={withdraw} />
        </div>
    );
};

export default AdminConsole;
