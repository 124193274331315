import React from 'react';

type PropsType = {
    name: string;
    small?: boolean;
};

const UserLogo: React.FC<PropsType> = ({ name, small }) => {
    return (
        <div className="user-logo-wrapper">
            <div className={`user-name ${small && 'small-text'}`}>{name}</div>
        </div>
    );
};

export default UserLogo;
