import React from 'react';
import { Container, Row, Col, Button, Toast, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { ActiveIcon, EstablishedIcon, InfoIcon, ProfitIcon, StartupIcon } from '../../../../assets/images';
import { InvestmentResponseType, ProfitsResponseType } from '../../../../models/api/dashboard-model';
import { formatCurrency, formatPercent } from '../../../../utils/utils';
import CircleChart from './../../../../components/cirlce-chart';
import RadialSeparators from '../../../../components/RadioSeparators';
import 'react-circular-progressbar/dist/styles.css';

type PropsType = {
    data?: ProfitsResponseType;
    investment?: InvestmentResponseType;
};

const DashboardPortfolios: React.FC<PropsType> = ({ data, investment }) => {
    const { t } = useTranslation();

    const startupDisclaimer = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('dashboard.startupDisclaimer')}
        </Tooltip>
    );

    const establishedDisclaimer = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('dashboard.establishedDisclaimer')}
        </Tooltip>
    );

    const activeDisclaimer = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('dashboard.activeDisclaimer')}
        </Tooltip>
    );

    const profitDisclaimer = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('dashboard.profitDisclaimer')}
        </Tooltip>
    );

    return (
        <Row>
            <Col xs={12} md={6} lg={3}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between header-startup">
                        <div className="startup-title">{t('dashboard.startup')}</div>
                        <img src={StartupIcon} alt="" />
                        <div className="startup-title">{formatCurrency(investment?.totalInvested.STARTUP)}</div>
                    </Card.Header>
                    <Card.Body className="d-flex justify-content-center">
                        <CircleChart percent={data?.fromPortfolioPercents.STARTUP} value={data?.fromPortfolio.STARTUP} color="#997dc7" hover />
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={startupDisclaimer}>
                            <Button className="bg-transparent border-0 startup-disclaimer">
                                <img src={InfoIcon} className="info-icon-size" alt="" />
                            </Button>
                        </OverlayTrigger>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={6} lg={3}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between header-established">
                        <div className="startup-title">{t('dashboard.established')}</div>
                        <img src={EstablishedIcon} alt="" />
                        <div className="startup-title">{formatCurrency(investment?.totalInvested.ESTABLISHED)}</div>
                    </Card.Header>
                    <Card.Body className="d-flex justify-content-center">
                        <CircleChart
                            percent={data?.fromPortfolioPercents.ESTABLISHED}
                            value={data?.fromPortfolio.ESTABLISHED}
                            color="#ef73b7"
                            hover
                        />
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={establishedDisclaimer}>
                            <Button className="bg-transparent border-0 established-disclaimer">
                                <img src={InfoIcon} className="info-icon-size" alt="" />
                            </Button>
                        </OverlayTrigger>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={6} lg={3}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between header-active">
                        <div className="startup-title">{t('dashboard.active')}</div>
                        <img src={ActiveIcon} alt="" />
                        <div className="startup-title">{formatCurrency(investment?.totalInvested.ACTIVE)}</div>
                    </Card.Header>
                    <Card.Body className="d-flex justify-content-center">
                        <CircleChart percent={data?.fromPortfolioPercents.ACTIVE} value={data?.fromPortfolio.ACTIVE} color="#00d2ff" hover />
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={activeDisclaimer}>
                            <Button className="bg-transparent border-0 active-disclaimer">
                                <img src={InfoIcon} className="info-icon-size" alt="" />
                            </Button>
                        </OverlayTrigger>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={6} lg={3}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between header-trader">
                        <div className="startup-title">{t('dashboard.profit')}</div>
                        <img src={ProfitIcon} alt="" />
                        <div>{/* <TraderItem /> */}</div>
                    </Card.Header>
                    <Card.Body>
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={activeDisclaimer}>
                            <Button className="bg-transparent border-0 active-disclaimer p-0">
                                <img src={InfoIcon} className="info-icon-size" alt="" />
                            </Button>
                        </OverlayTrigger>
                        <div className="profit-item ">
                            <div className="profit-progress">
                                <CircularProgressbarWithChildren
                                    value={data?.termBasedProfitData.shortPercents ?? 0}
                                    text={formatPercent(data?.termBasedProfitData.shortPercents)}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                        strokeLinecap: 'butt',
                                    })}
                                >
                                    <RadialSeparators
                                        count={35}
                                        style={{
                                            background: 'black',
                                            width: '5px',
                                            // This needs to be equal to props.strokeWidth
                                            height: `${10}%`,
                                        }}
                                    />
                                </CircularProgressbarWithChildren>
                            </div>
                            <div className="profit-text-wrapper">
                                <div className="profit-text-primary">{formatCurrency(data?.termBasedProfitData.shortProfits)}</div>
                                <div className="profit-text-secondary">{t('dashboard.shortProfit')}</div>
                            </div>
                        </div>
                        <div className="profit-item ">
                            <div className="profit-progress">
                                <CircularProgressbarWithChildren
                                    value={data?.termBasedProfitData.longProfits ?? 0}
                                    text={formatPercent(data?.termBasedProfitData.longPercents)}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                        strokeLinecap: 'butt',
                                    })}
                                >
                                    <RadialSeparators
                                        count={35}
                                        style={{
                                            background: 'black',
                                            width: '5px',
                                            // This needs to be equal to props.strokeWidth
                                            height: `${10}%`,
                                        }}
                                    />
                                </CircularProgressbarWithChildren>
                            </div>
                            <div className="profit-text-wrapper">
                                <div className="profit-text-primary">{formatCurrency(data?.termBasedProfitData.longProfits)}</div>
                                <div className="profit-text-secondary">{t('dashboard.longProfit')}</div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default DashboardPortfolios;
