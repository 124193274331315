import { useCallback } from 'react';
import { RolesEnum } from '../models/enums';
import { useUser } from './../context/user-context';

type UseRoleType = {
    hasRole: (value: RolesEnum) => boolean;
};

export function useRoles(): UseRoleType {
    const { user } = useUser();
    const hasRole = useCallback(
        (role: string): boolean => {
            return !!user?.roles?.includes(role);
        },
        [user],
    );

    return { hasRole };
}
