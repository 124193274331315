import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SeifLogo } from '../../../assets/images';
import BaseButton from '../../../components/common/base-button';
import BaseInput from '../../../components/common/base-input';
import { LoginDataType } from '../../../models/api/auth-models';
import { BaseFormType } from '../../../models/models';
import { AuthRoutes } from '../../../routes/routes';
import LoadingIndicator from '../../../components/loading-indicator';
import { useFormRules } from '../../../hooks/use-form-rules';
import { useTranslation } from 'react-i18next';
import PasswordInput from './../../../components/common/password-input';

type PropsType = BaseFormType<LoginDataType> & {
    handleLogin: () => void;
    loading: boolean;
};

const LoginView: React.FC<PropsType> = ({ handleLogin, register, errors, trigger, loading }) => {
    const { requiredTrimed, formRules } = useFormRules();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <div className="auth-container auth-padding">
            <LoadingIndicator visible={loading} />
            <div className="auth-logo">
                <img src={SeifLogo} alt="" />
            </div>
            <form onSubmit={handleLogin}>
                <BaseInput
                    {...register('userName', { ...requiredTrimed })}
                    errors={errors}
                    type="text"
                    label={t('auth.username')}
                    trigger={trigger}
                />
                <PasswordInput
                    {...register('password', { ...requiredTrimed, ...formRules.minLengthX(6) })}
                    errors={errors}
                    paswordVisible={showPassword}
                    handlePasswordVisibility={() => setShowPassword(!showPassword)}
                    label={t('auth.password')}
                    trigger={trigger}
                />
                <div className="singin-text-btns">
                    <span style={{ color: '#8a8e9c' }}>Support:</span>
                    <a href="mailto:support@seifgroup.io" className="support-link">
                        support@seifgroup.io
                    </a>
                    <div> </div>
                    <Link to={AuthRoutes.FORGOT_PASSWORD} replace>
                        {t('auth.forgot-password')}
                    </Link>
                </div>

                <BaseButton type="submit" className="auth-sign-in-btn btn-center mt-4">
                    {t('auth.login')}
                </BaseButton>
            </form>
        </div>
    );
};

export default LoginView;
