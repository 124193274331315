import React from 'react';
import { Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { RolesEnum } from '../../models/enums';
import { AdminRoutes } from '../../routes/routes';
import BaseHeader from './../../components/header/base-header';
import BaseSidebar from './../../components/sidebar/base-sidebar';
import RoleRoute from './../../routes/guard/role-route';
import Dashboard from './dashboard/dashboard';
import Employees from './employees/employees';
import Refferals from './refferals/refferals';
import Trades from './trades/trades';
import { useTranslation } from 'react-i18next';
import WithdrawalRequests from './withdrawal-requests/withdrawal-requests';
import AdminConsole from './admin-console/admin-console';
import ClientStatistic from './statistic/client-statistic';
import SaleStatistic from './statistic/sale-statistic';
import ReferralStatistic from './statistic/referal-statistic';
import AdminAffiliate from './affiliate/affiliate';
import InboxManager from './inbox-manager/InboxManager';
import AdminClients from './clients/admin-clients';
import ClientDashboard from './client-dashboard/ClientDashboard';
import { Container } from 'react-bootstrap';
import WithdrawStatistic from './statistic/withdraw-statistic';
import RegisteredUsers from './registered-users/registered-users';
import { useNotification } from '../../context/notification-context';

const Admin: React.FC = () => {
    const { t } = useTranslation();

    const { withdrawalRequestCount } = useNotification();

    const adminLinks = [
        {
            to: AdminRoutes.DASHBOARD,
            icon: '',
            title: t('navlinks.dashboard'),
        },
        {
            to: AdminRoutes.CLIENT_DASHBOARD,
            icon: '',
            title: t('navlinks.clientDashboard'),
        },
        {
            to: AdminRoutes.CLIENT_STATISTIC,
            icon: '',
            title: t('navlinks.clientStatistic'),
        },
        {
            to: AdminRoutes.SALE_STATISTIC,
            icon: '',
            title: t('navlinks.saleStatistic'),
        },
        {
            to: AdminRoutes.REFERAL_STATISTIC,
            icon: '',
            title: t('navlinks.referralStatistic'),
        },
        {
            to: AdminRoutes.WITHDRAWAL_STATISTIC,
            icon: '',
            title: t('navlinks.withdrawalStatistic'),
        },
        {
            to: AdminRoutes.EMPLOYEES,
            icon: '',
            title: t('navlinks.employees'),
        },
        {
            to: AdminRoutes.REFFERALS,
            icon: '',
            title: t('navlinks.refferals'),
        },
        {
            to: AdminRoutes.CLIENTS,
            icon: '',
            title: t('navlinks.clients'),
        },
        {
            to: AdminRoutes.AFFILIATE,
            icon: '',
            title: t('navlinks.affiliate'),
        },
        {
            to: AdminRoutes.REGISTERED_USERS,
            icon: '',
            title: t('navlinks.registered'),
        },
        {
            to: AdminRoutes.TRADES,
            icon: '',
            title: t('navlinks.trades'),
        },
        {
            to: AdminRoutes.WITHDRAWAL_REQUESTS,
            icon: '',
            title: t('navlinks.withrawalRequests'),
            count: withdrawalRequestCount,
        },
        {
            to: AdminRoutes.ADMIN_CONSOLE,
            icon: '',
            title: t('navlinks.adminConsole'),
        },
        {
            to: AdminRoutes.INBOX_MANAGER,
            icon: '',
            title: t('navlinks.iboxManager'),
        },
    ];
    return (
        <>
            <div className="container-fluid app-container">
                <BaseSidebar links={adminLinks} />
                <div>
                    <BaseHeader />
                    <div className="page-content m-4">
                        <Switch>
                            <RoleRoute exact path={AdminRoutes.DASHBOARD} component={Dashboard} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.CLIENT_DASHBOARD} component={ClientDashboard} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.CLIENT_STATISTIC} component={ClientStatistic} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.SALE_STATISTIC} component={SaleStatistic} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.REFERAL_STATISTIC} component={ReferralStatistic} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.WITHDRAWAL_STATISTIC} component={WithdrawStatistic} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.EMPLOYEES} component={Employees} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.AFFILIATE} component={AdminAffiliate} role={RolesEnum.ADMIN} />
                            <RoleRoute path={AdminRoutes.REFFERALS} component={Refferals} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.CLIENTS} component={AdminClients} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.REGISTERED_USERS} component={RegisteredUsers} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.TRADES} component={Trades} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.WITHDRAWAL_REQUESTS} component={WithdrawalRequests} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.ADMIN_CONSOLE} component={AdminConsole} role={RolesEnum.ADMIN} />
                            <RoleRoute exact path={AdminRoutes.INBOX_MANAGER} component={InboxManager} role={RolesEnum.ADMIN} />
                            <Redirect to={AdminRoutes.DASHBOARD} />
                        </Switch>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Admin;
