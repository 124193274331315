import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { ProfitIcon } from '../../../assets/images';
import {
    InvestmentResponseType,
    PortfolioHistoryType,
    PortfolioType,
    ProfitsResponseType,
    PrortfolioResponseType,
    UserHistoryResponseType,
} from '../../../models/api/dashboard-model';
import { HistoryEnum } from '../../../models/enums';
import clientService from '../../../service/clientService';
import BonusWidget, { BonusWidgetEnum } from '../../admin/statistic/components/bonus-widget';
import DashboardChart from './components/dashboard-chart';
import DashboardPortfolios from './components/dashboard-portfolios';
import DashboardRefferal from './components/dashboard-refferal';
import DashboradTotal from './components/dashboard-total';

const Dashboard: React.FC = () => {
    const [, setPortfolioList] = useState<PortfolioType[]>([]);
    const [profits, setProfits] = useState<ProfitsResponseType>();
    const [investment, setInvestment] = useState<InvestmentResponseType>();
    const [history, setHistory] = useState<PortfolioHistoryType>();

    useEffect(() => {
        clientService.getPortfolios().then(({ data }: AxiosResponse<PrortfolioResponseType>) => {
            setPortfolioList(data.portfolios);
        });
        clientService.getProfits().then(({ data }: AxiosResponse<ProfitsResponseType>) => {
            setTimeout(() => {
                setProfits(data);
            }, 300);
        });
        clientService.getInvestment().then(({ data }: AxiosResponse<InvestmentResponseType>) => {
            setInvestment(data);
        });
        clientService.getHistory(HistoryEnum.YEAR).then(({ data }: AxiosResponse<UserHistoryResponseType>) => {
            const historyValue = { ...data.history, BALANCE: data.currentBalanceHistory };
            setHistory(historyValue);
        });
    }, []);

    const summTotalProfit = useMemo(() => {
        if (profits) {
            const prof = profits.fromPortfolio.ACTIVE + profits.fromPortfolio.ESTABLISHED + profits.fromPortfolio.STARTUP;
            return prof;
        }
        return 0;
    }, [profits]);

    return (
        <div>
            <DashboardPortfolios data={profits} investment={investment} />
            <Row>
                <Col xs={12} md={12} lg={8} className="mb-4">
                    <DashboardChart history={history} />
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <Col xs={12} md={6} lg={12} className="mb-4">
                        <DashboradTotal
                            total={summTotalProfit}
                            firstWeekTradingInvestmentsAmount={investment?.firstWeekTradingInvestmentsAmount}
                            pendingInvestmentsAmount={investment?.pendingInvestmentsAmount}
                        />
                    </Col>
                    <Col xs={12} md={6} lg={12}>
                        {/* <DashboardRefferal refferalAmount={profits?.fromReferrals} bonusesByMonths={profits?.bonusesByMonths} /> */}
                        <BonusWidget data={profits?.bonusesByMonths} type={BonusWidgetEnum.INVEST} isReferal />
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default Dashboard;
