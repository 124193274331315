import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SeifLogo } from '../../../assets/images';
import BaseButton from '../../../components/common/base-button';
import LoadingIndicator from '../../../components/loading-indicator';
import { useFormRules } from '../../../hooks/use-form-rules';
import { ResetDataType } from '../../../models/api/auth-models';
import { BaseFormType } from '../../../models/models';
import PasswordInput from './../../../components/common/password-input';

type PropsType = BaseFormType<ResetDataType> & {
    handleReset: () => void;
    handleClose: () => void;
    loading: boolean;
    isOpened: boolean;
};

const ResetPasswordView: React.FC<PropsType> = ({ handleReset, register, errors, trigger, watch, loading, handleClose, isOpened }) => {
    const { formRules, equals } = useFormRules();
    const { t } = useTranslation();

    const password = (watch && watch('newPassword')) ?? '';
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    return (
        <>
            <LoadingIndicator visible={loading} />

            <Modal show={isOpened} onHide={handleClose} centered>
                <Modal.Body className="auth-modal">
                    <div className="auth-container auth-padding">
                        <div className="auth-logo">
                            <img src={SeifLogo} alt="" />
                        </div>
                        <form onSubmit={handleReset}>
                            <PasswordInput
                                {...register('newPassword', { ...formRules.minLengthX(6) })}
                                errors={errors}
                                trigger={trigger}
                                paswordVisible={showPassword}
                                handlePasswordVisibility={() => setShowPassword(!showPassword)}
                                label={t('auth.password')}
                            />

                            <PasswordInput
                                {...register('newPasswordConfirm', { ...equals(password, 'password') })}
                                errors={errors}
                                trigger={trigger}
                                paswordVisible={showConfirmPassword}
                                handlePasswordVisibility={() => setShowConfirmPassword(!showConfirmPassword)}
                                label={t('auth.passwordConfirm')}
                            />

                            <BaseButton type="submit" className="auth-sign-in-btn btn-center mt-4">
                                {t('auth.reset')}
                            </BaseButton>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ResetPasswordView;
