import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { PayoutRequestResponseType, PayoutRequestType } from '../../../models/api/dashboard-model';
import adminService from '../../../service/adminService';
import RequestItem from './components/request-item';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import Paginator from '../../../components/paginator';
import { useDebounce } from '../../../hooks/use-debounce';

const WithdrawalRequests: React.FC = () => {
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [requests, setRequests] = useState<PayoutRequestType[]>([]);
    const [pagination, setPagination] = useState<PayoutRequestResponseType>();
    const [pageIndex, setPageIndex] = useState<number>(0);

    const debounceNameSearch = useDebounce<string>(name.trim(), 600);
    const debounceEmailSearch = useDebounce<string>(email.trim(), 600);

    const laodRequests = (name: string, email: string, page: number) => {
        adminService.getPayoutRequests(name, email, page).then((res) => {
            setRequests(res.data.requests.content);
            setPagination(res.data);
        });
    };

    useEffect(() => {
        laodRequests(debounceNameSearch, debounceEmailSearch, pageIndex);
    }, [pageIndex, debounceNameSearch, debounceEmailSearch]);

    const approveWithdraw = (id: number) => {
        adminService.payoutApproveRequest(id).then(() => {
            addToast(t('toast.paymentApproved'));
            laodRequests(debounceNameSearch, debounceEmailSearch, pageIndex);
        });
    };

    const declineWithdraw = (id: number) => {
        adminService.payoutDeclineRequest(id).then(() => {
            addToast(t('toast.paymentDeclined'));
            laodRequests(debounceNameSearch, debounceEmailSearch, pageIndex);
        });
    };

    return (
        <>
            <div className="affiliate-grid-container client-container">
                <div className="search-wrapper">
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="nameSearch" className="form-label mb-2">
                            {t('common.nameSearch')}
                        </label>
                    </div>
                    <div className="form-floating">
                        <input
                            type="text"
                            id=""
                            placeholder=" "
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            autoComplete="OFF"
                            className={'form-control'}
                        />
                        <label htmlFor="nameSearch" className="form-label mb-2">
                            Email Search
                        </label>
                    </div>
                </div>
                <Accordion defaultActiveKey="0">
                    {requests.map((i, index) => (
                        <RequestItem
                            key={index}
                            eventKey={`key${index}`}
                            item={i}
                            approveWithdraw={() => approveWithdraw(i.id)}
                            declineWithdraw={() => declineWithdraw(i.id)}
                        />
                    ))}
                </Accordion>
                <Paginator
                    pageIndex={pageIndex}
                    dataLength={pagination?.requests.totalElements}
                    onPageChanged={(newPage: number) => {
                        setPageIndex(newPage);
                    }}
                    pageSize={20}
                />
            </div>
        </>
    );
};

export default WithdrawalRequests;
