/* eslint-disable react/no-children-prop */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ForgotPassword from '../pages/auth/forgot-password/forgot-password';
import Login from '../pages/auth/login/login';
import MessageModal from '../pages/auth/message-modal/message-modal';
import Registration from '../pages/auth/registration/registration';
import RegistrationConfirm from '../pages/auth/registration/registration-confirm';
import ResetPassword from '../pages/auth/reset-password/reset-password';
import { AuthRoutes } from './routes';

type PropsType = {
    onClose: (root?: boolean) => void;
};

const LandingRouteList: React.FC<PropsType> = ({ onClose }) => {
    return (
        <Switch>
            {/* <Route exact path={AuthRoutes.REGISTRATION} children={({ match }) => <Registration isOpened={Boolean(match)} onClose={onClose} />} /> */}
            <Route
                exact
                path={`${AuthRoutes.REGISTRATION}${AuthRoutes.PROCESS_SUCCESS}`}
                children={({ match }) => <MessageModal isOpened={Boolean(match)} onClose={() => onClose(true)} />}
            />
            <Route
                exact
                path={`${AuthRoutes.REGISTRATION_EMPLOYEE}${AuthRoutes.PROCESS_SUCCESS}`}
                children={({ match }) => <MessageModal isOpened={Boolean(match)} onClose={() => onClose(true)} />}
            />
            <Route
                exact
                path={`${AuthRoutes.REGISTRATION_CLIENT}${AuthRoutes.PROCESS_SUCCESS}`}
                children={({ match }) => <MessageModal isOpened={Boolean(match)} onClose={() => onClose(true)} />}
            />
            <Route
                exact
                path={AuthRoutes.REGISTRATION_CONFIRM}
                children={({ match }) => <RegistrationConfirm isOpened={Boolean(match)} onClose={onClose} />}
            />
            <Route
                exact
                path={AuthRoutes.REGISTRATION_CLIENT}
                children={({ match }) => <Registration isOpened={Boolean(match)} onClose={() => onClose(true)} />}
            />
            <Route
                exact
                path={AuthRoutes.REGISTRATION_REFERRAL}
                children={({ match }) => <Registration isOpened={Boolean(match)} onClose={() => onClose(true)} />}
            />
            <Route
                exact
                path={`${AuthRoutes.REGISTRATION_REFERRAL}${AuthRoutes.PROCESS_SUCCESS}`}
                children={({ match }) => <MessageModal isOpened={Boolean(match)} onClose={() => onClose(true)} />}
            />
            <Route
                exact
                path={AuthRoutes.REGISTRATION_EMPLOYEE}
                children={({ match }) => <Registration isOpened={Boolean(match)} onClose={() => onClose(true)} />}
            />
            <Route exact path={AuthRoutes.FORGOT_PASSWORD} children={({ match }) => <ForgotPassword isOpened={Boolean(match)} onClose={onClose} />} />
            <Route
                exact
                path={`${AuthRoutes.FORGOT_PASSWORD}${AuthRoutes.PROCESS_SUCCESS}`}
                children={({ match }) => <MessageModal isOpened={Boolean(match)} onClose={onClose} />}
            />
            <Route
                exact
                path={AuthRoutes.RESET_PASSWORD}
                children={({ match }) => <ResetPassword isOpened={Boolean(match)} onClose={() => onClose(true)} />}
            />
        </Switch>
    );
};

export default LandingRouteList;
