import React from 'react';
import { useTranslation } from 'react-i18next';

type PropsType = {
    link: string;
    onCopy: () => void;
    onSend: () => void;
};

const RefferalLinkWidget: React.FC<PropsType> = ({ link, onCopy, onSend }) => {
    const { t } = useTranslation();
    return (
        <div className="link-container">
            <div className="link-wrapper">
                <div className="link-title">{t('refferals.refferalLink')}</div>
                <div className="link-url">{link}</div>
            </div>
            <div className="link-btns-group">
                <button className="btn-coppy" onClick={onCopy}>
                    {t('common.coppy')}
                </button>
                <button className="btn-send" onClick={onSend}>
                    {t('auth.send')}
                </button>
            </div>
        </div>
    );
};

export default RefferalLinkWidget;
