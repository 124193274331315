import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ClientStatisticPortfolioType, InvestmentHistory } from '../../../../models/api/dashboard-model';
import InvestHistoryItem from './invest-history-item';
import { useUser } from '../../../../context/user-context';
import { useRoles } from '../../../../hooks/use-roles';
import { RolesEnum } from '../../../../models/enums';
import adminService from '../../../../service/adminService';
import employeeService from '../../../../service/employeeService';
import clientService from '../../../../service/clientService';

type PropsType = {
    investmentHistory?: InvestmentHistory[];
};

const InvestHistory: React.FC<PropsType> = ({ investmentHistory }) => {
    const { t } = useTranslation();
    const { hasRole } = useRoles();

    const [activeInvestDetail, setActiveDetail] = useState<ClientStatisticPortfolioType[]>([]);

    const loadInvestDetail = (id: number) => {
        if (hasRole(RolesEnum.ADMIN) || hasRole(RolesEnum.SUPERVISOR))
            adminService.getClientPortfolios(id).then((res) => {
                setActiveDetail(res.data);
            });
        else if (hasRole(RolesEnum.EMPLOYEE))
            employeeService.getClientPortfolios(id).then((res) => {
                setActiveDetail(res.data);
            });
        else if (hasRole(RolesEnum.USER)) {
            clientService.getPortfoliosByPayment(id).then((res) => {
                setActiveDetail(res.data);
            });
        }
    };

    return (
        <div className="history">
            <div className="history-title">{t('invest.historyInvest')}</div>
            {investmentHistory ? (
                <Accordion defaultActiveKey="0">
                    {investmentHistory.map((item, index) => {
                        return (
                            <InvestHistoryItem
                                item={item}
                                key={index}
                                eventKey={`${item.id}`}
                                loadInvestDetail={loadInvestDetail}
                                activeInvestDetail={activeInvestDetail}
                            />
                        );
                    })}
                </Accordion>
            ) : (
                <>
                    <div className="history-empty">
                        <div className="divider"></div>
                        <div className="divider"></div>
                        <div className="divider"></div>
                    </div>
                </>
            )}
        </div>
    );
};

export default InvestHistory;
