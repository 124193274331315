import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { ActiveIcon, ClientsLogo, EstablishedIcon, StartupIcon } from '../../../../assets/images';
import { useDebounce } from '../../../../hooks/use-debounce';
import { useRoles } from '../../../../hooks/use-roles';
import { ClientSimplified, InvestmentResponseType, ProfitsResponseType, StatisticPortfolioType } from '../../../../models/api/dashboard-model';
import { RolesEnum } from '../../../../models/enums';
import adminService from '../../../../service/adminService';
import employeeService from '../../../../service/employeeService';
import { formatCurrency } from '../../../../utils/utils';
import CircleChart from './../../../../components/cirlce-chart';

type PropsType = {
    data?: ProfitsResponseType;
    investment?: InvestmentResponseType;
    client?: ClientSimplified;
    handleUsername: (user?: ClientSimplified) => void;
    employeeData?: StatisticPortfolioType;
};

const DashboardEmpPortfolios: React.FC<PropsType> = ({ data, investment, client, handleUsername, employeeData }) => {
    const { t } = useTranslation();

    const [name, setName] = useState<string>('');
    const [clientsSimplified, setClientclientsSimplified] = useState<ClientSimplified[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    const debounceSearch = useDebounce<string>(name.trim(), 600);

    const { hasRole } = useRoles();

    const userOptions = useMemo(() => {
        return clientsSimplified.map((item) => {
            return {
                id: item.id,
                label: `${item.name} ${item.surname}`,
            };
        });
    }, [clientsSimplified]);

    useEffect(() => {
        if (debounceSearch.trim().length > 0) {
            setLoading(true);
            if (hasRole(RolesEnum.ADMIN) || hasRole(RolesEnum.SUPERVISOR))
                adminService
                    .getClientsSimplified(debounceSearch)
                    .then((res) => {
                        setClientclientsSimplified(res.data.users);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            else if (hasRole(RolesEnum.EMPLOYEE))
                employeeService
                    .getClientsSimplified(debounceSearch)
                    .then((res) => {
                        setClientclientsSimplified(res.data.users);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
        }
    }, [debounceSearch, hasRole]);

    return (
        <div className="portfolio-grid-container">
            <div className="startup">
                <div className="item-header">
                    <div className="title">{t('dashboard.startup')}</div>
                    <img src={StartupIcon} alt="" />
                    <div className="item-invest">{formatCurrency(investment?.totalInvested.STARTUP ?? employeeData?.STARTUP)}</div>
                </div>
                <div className="item-chart">
                    <CircleChart percent={data?.fromPortfolioPercents.STARTUP ?? 0} value={data?.fromPortfolio.STARTUP} color="#997dc7" hover />
                </div>
            </div>
            <div className="established">
                <div className="item-header">
                    <div className="title">{t('dashboard.established')}</div>
                    <img src={EstablishedIcon} alt="" />
                    <div className="item-invest">{formatCurrency(investment?.totalInvested.ESTABLISHED ?? employeeData?.ESTABLISHED)}</div>
                </div>
                <div className="item-chart">
                    <CircleChart
                        percent={data?.fromPortfolioPercents.ESTABLISHED ?? 0}
                        value={data?.fromPortfolio.ESTABLISHED}
                        color="#ef73b7"
                        hover
                    />
                </div>
            </div>
            <div className="active">
                <div className="item-header">
                    <div className="title">{t('dashboard.active')}</div>
                    <img src={ActiveIcon} alt="" />
                    <div className="item-invest">{formatCurrency(investment?.totalInvested.ACTIVE ?? employeeData?.ACTIVE)}</div>
                </div>
                <div className="item-chart">
                    <CircleChart percent={data?.fromPortfolioPercents.ACTIVE ?? 0} value={data?.fromPortfolio.ACTIVE} color="#00d2ff" hover />
                </div>
            </div>
            <div className="trader">
                <div className="item-header">
                    <div className="title">{t('dashboard.clients')}</div>
                    {/* <FontAwesomeIcon icon={faSync} color="#fdd2b9" role="button" /> */}
                    <img src={ClientsLogo} alt="" />
                    <div></div>
                </div>
                <div className="console-autocomplete">
                    <label htmlFor="exampleDataList" className="form-label"></label>
                    <AsyncTypeahead
                        isLoading={isLoading}
                        className="search-typehead"
                        id="searchClient"
                        placeholder="Search client"
                        onSearch={setName}
                        onChange={(selected) => {
                            if (selected.length > 0) {
                                handleUsername(clientsSimplified.find((item) => item.id === selected[0].id));
                            } else {
                                handleUsername(undefined);
                                // loadUsers(0);
                            }
                        }}
                        options={userOptions}
                    />
                </div>
                {client && !client.isEnabled && client.disabledFrom != null && (
                    <div className="disabled-wrapper">
                        <div className="secondary-text bg-danger font-weight-bold text-uppercase text-white p-1 m-1 rounded">Disabled</div>
                        <div className="primary-text">{moment(client.disabledFrom).format('DD.MM.YYYY')}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardEmpPortfolios;
